import React, { useState } from "react";
import {
  Checkbox,
  Avatar,
  Divider,
  Typography,
  Link,
  Grid,
  Box,
  Collapse,
  Badge,
  Drawer,
} from "@material-ui/core";
import { pink, red } from "@material-ui/core/colors";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import validator from "validator";
import StarIcon from "../../Assets/CustomIcons/StarIcon";
import LastModified from "../../components/LastModified/LastModified";
import ErrorOutlineRoundedIcon from "@material-ui/icons/ErrorOutlineRounded";
import { getInitials } from "../../config/candidatesConfigData";
import SingleCandidateActions from "./SingleCandidateActions";
import StyledRating from "../../components/StyledRating/StyledRating";
import EventRow from "./SingleCandidateFull/CandidateEvents/EventRow";
import axios from "axios";
import Spinner from "../../UI/Spinner/Spinner";
import CustomPopover from "../../components/CustomPopover/CustomPopover";
import { Fragment } from "react";
import FailureReasons from "../../components/LastQuestionnaireFailureReasons/FailureReasons";
import {
  AI_LICENSES,
  AI_LOCATION,
  AI_SKILLS,
  AI_ACADEMIC_DGREE,
  AI_CERTIFICATES,
  AI_LANGUAGES,
  AI_DYNAMICS,
  AI_AVAILABILITY,
  AI_SALARY_EXPECTATIONS,
} from "../../config/constants";
import JobAcceptedQuestionierSummary from "../../components/JobAcceptedQuestionierSummary/JobAcceptedQuestionierSummary";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    "& .MuiBadge-badge": {
      right: -15,
      top: 11,
    },
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flexWrap: "wrap",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  active: {
    "& > div": {
      backgroundColor: pink[50],
    },
  },
}));

const removeCharsFromMobile = (mobile) => {
  return mobile && mobile.replace("-", "");
};

const SingleCandidate = ({
  shortVersion,
  isActive,
  underJobDashboard,
  ...props
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const [isLoading, setIsLoading] = React.useState(false);
  const [activeMenuItem, setActiveMenuItem] = React.useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchoreFailureEl, setAnchorFailureEl] = React.useState(null);
  const [showLastQuestionnaire, setShowLastQuestionnaire] = useState(false);

  const handleMoreActionsMenuClick = (event, actionId, actionValue) => {
    setActiveMenuItem(actionId);
  };

  const handleDownloadCV = () => {
    setIsLoading(true);
    axios
      .post(
        `https://services.adamtotal.co.il/ADMBI/RequestFiles/clientno/1021/user/lynadmin/password/9PiuGwyPzQPAoCjQHdwO/candno/${parseInt(
          props.candidateData.acf.externalNumber
        )}`,
        {},
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
            "Access-Control-Allow-Headers":
              "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
          },
        }
      )
      .then((res) => {
        const cvsRaw = res.data.map((file) => {
          return {
            fileContent: file.file_content,
            fileType: file.file_ext,
            fileName: file.file_name,
          };
        });
        const base64 = cvsRaw[0].fileContent;
        const link = document.createElement("a");
        link.href =
          "data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64," +
          base64;
        link.download = cvsRaw[0].fileName;
        link.click();
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const handleAddEvent = (
    type,
    value,
    additionalInfo = "",
    isUpdateMode = false
  ) => {
    setActiveMenuItem("");
    props.eventAdded(
      {
        eventKey: type,
        eventValue: value,
        eventAuthor: localStorage.getItem("userId"),
        additionalInfo: additionalInfo,
        isUpdateMode,
      },
      props.candidateData.id
    );
  };

  const formatFailureReasons = (failureReason) => {
    switch (failureReason.key) {
      case "location":
        return "מיקום";
      case "experience":
        return "שאלת ניסיון מקצועי מספר " + failureReason.id;
      case AI_SKILLS:
        return "שאלת יכולות מספר" + failureReason.id;
      case AI_LICENSES:
        return "שאלת רשיונות מספר " + failureReason.id;
      case AI_ACADEMIC_DGREE:
        return "שאלת תארים אקדמיים מספר" + failureReason.id;
      case AI_CERTIFICATES:
        return "שאלת תעודות מספר " + failureReason.id;
      case AI_LANGUAGES:
        return "שאלת שפות מספר " + failureReason.id;
      case (AI_SALARY_EXPECTATIONS, "salary"):
        return "שאלת ציפיות שכר";
      case AI_DYNAMICS:
        return "שאלה דינאמית מספר " + failureReason.id;
      case AI_LOCATION:
        return "שאלת מיקום";
      case AI_AVAILABILITY:
        return "שאלת זמינות";
      default:
        return "";
    }
  };

  return (
    <React.Fragment>
      <Spinner open={isLoading} />
      <Grid
        container
        spacing={2}
        justifyContent="flex-start"
        alignItems="center"
        className={classes.root}
      >
        <Grid
          item
          xs={shortVersion ? 12 : 2}
          className={isActive ? classes.active : ""}
        >
          <div className={classes.row}>
            <Checkbox
              checked={isActive}
              name="checkCandidate"
              onClick={(event) => props.check(event, props.candidateData.id)}
              color="primary"
            />
            <Avatar
              style={{
                marginLeft: theme.spacing(1),
                width: theme.spacing(4),
                height: theme.spacing(4),
              }}
              alt={props.candidateData.name}
              src={
                props.candidateData && props.candidateData.profile_image
                  ? props.candidateData.profile_image
                  : ""
              }
            >
              {getInitials(props.candidateData.name)}
            </Avatar>
            <Typography variant="subtitle1">
              <Link
                style={{
                  cursor: "pointer",
                  textDecoration: "none",
                }}
                color="textPrimary"
                onClick={(e) => props.selectId(e, props.candidateData.id)}
              >
                {props.candidateData.name}
              </Link>
            </Typography>
          </div>
        </Grid>
        {!shortVersion && (
          <React.Fragment>
            <Grid item xs={1}>
              <Typography
                variant="body1"
                color="textPrimary"
                onMouseEnter={(event) => setAnchorEl(event.currentTarget)}
                onMouseLeave={() => setAnchorEl(null)}
              >
                {props.candidateData.acf.accepted_cities.length > 0 ? (
                  <Badge
                    badgeContent={
                      props.candidateData.acf.accepted_cities.length
                    }
                    color="primary"
                    showZero={false}
                  >
                    {props.candidateData.acf.accepted_cities[0]}
                  </Badge>
                ) : (
                  props.candidateData.acf.city
                )}
              </Typography>
              <CustomPopover
                anchorEl={anchorEl}
                onClosePoper={() => setAnchorEl(null)}
              >
                <Fragment>
                  <Typography variant="h6">ערים מתאימות למועמד:</Typography>
                  {props.candidateData.acf.accepted_cities.map((city) => (
                    <Typography variant="body1" key={city}>
                      {city}
                    </Typography>
                  ))}
                </Fragment>
              </CustomPopover>
            </Grid>
            <Grid item xs={1}>
              <Typography variant="body1" color="textPrimary">
                {props.candidateData.professions[0]
                  ? props.candidateData.professions[0].name
                  : ""}
              </Typography>
            </Grid>

            <Grid item xs={1}>
              <Typography variant="body1" color="textPrimary">
                {props.candidateData.acf.externalNumber}
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <Box display="flex" alignItems="center">
                <Typography variant="body1" color="textPrimary">
                  {props.candidateData.acf.mobile}{" "}
                </Typography>
                {props.candidateData.acf.mobile &&
                  !validator.isMobilePhone(
                    removeCharsFromMobile(props.candidateData.acf.mobile),
                    "he-IL"
                  ) && <ErrorOutlineRoundedIcon style={{ color: red[500] }} />}
              </Box>
            </Grid>
            <Grid item xs={1}>
              <Typography variant="body1" color="textPrimary">
                {props.candidateData.acf.source}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              {props.candidateData.failure_reasons ? (
                <Grid
                  item
                  onMouseEnter={(event) => {
                    setAnchorFailureEl(event.currentTarget);
                  }}
                  onMouseLeave={() => setAnchorFailureEl(null)}
                >
                  <FailureReasons
                    data={props.candidateData.failure_reasons}
                    onClick={() => setShowLastQuestionnaire(true)}
                  />
                  <CustomPopover
                    anchorEl={anchoreFailureEl}
                    onClosePoper={() => setAnchorFailureEl(null)}
                  >
                    <Fragment>
                      <Typography variant="h6">סיבות הדחייה:</Typography>
                      {props.candidateData.failure_reasons.map((failure) => (
                        <Typography
                          variant="body1"
                          key={`${failure.key}_${failure.id}`}
                        >
                          {formatFailureReasons(failure)}
                        </Typography>
                      ))}
                    </Fragment>
                  </CustomPopover>
                </Grid>
              ) : (
                <StyledRating
                  value={
                    props.candidateData.acf.rating
                      ? parseFloat(props.candidateData.acf.rating)
                      : -1
                  }
                  precision={props.ratingPrecisionHalf ? 0.5 : 1}
                  readOnly
                  icon={<StarIcon strokeColor={"#8593FF"} />}
                />
              )}
            </Grid>
            <Grid item xs={1}>
              <LastModified toDate={props.candidateData.post_modified} />
            </Grid>
            <Grid item xs={2}>
              <SingleCandidateActions
                menuItemClick={handleMoreActionsMenuClick}
                downloadCV={handleDownloadCV}
                quickViewData={props.candidateData}
              />
            </Grid>
          </React.Fragment>
        )}
      </Grid>
      {activeMenuItem && (
        <Collapse in={true}>
          <Box p={theme.spacing(2)}>
            <EventRow
              eventType={activeMenuItem}
              draftMode
              authorImage={localStorage.getItem("userImage")}
              disableLastModified
              onAddEvent={handleAddEvent}
              cancelEvent={() => setActiveMenuItem("")}
            />
          </Box>
        </Collapse>
      )}
      {showLastQuestionnaire && (
        <Drawer
          anchor={"right"}
          open={showLastQuestionnaire}
          onClose={() => setShowLastQuestionnaire(false)}
        >
          <Box p={2} width={500}>
            <Typography paragraph variant="subtitle1">
              {props.candidateData.name}, תשובות למשרה {underJobDashboard}
            </Typography>
            <JobAcceptedQuestionierSummary
              onClose={() => setShowLastQuestionnaire(false)}
              initClosed={false}
              candidateId={props.candidateData.id}
              jobId={underJobDashboard}
            />
          </Box>
        </Drawer>
      )}
      <Divider />
    </React.Fragment>
  );
};
export default SingleCandidate;
