import React from "react";
import pure from "recompose/pure";
import { SvgIcon } from "@material-ui/core";

let DownloadCVIcon = ({ strokeColor, ...props }) => (
  <SvgIcon {...props}>
    <path
      d="M13 10H18L12 16L6 10H11V3H13V10ZM4 19H20V12H22V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V12H4V19Z"
      stroke={strokeColor ? strokeColor : "#363740"}
      strokeWidth="1.5"
    />
  </SvgIcon>
);
DownloadCVIcon = pure(DownloadCVIcon);
DownloadCVIcon.displayName = "StarIcon";
DownloadCVIcon.muiName = "SvgIcon";

export default DownloadCVIcon;
