import axios from "axios";

export const AreasList = {
  0: "כל הארץ",
  601: "אילת והערבה",
  602: "גוש דן",
  603: "גוש שגב",
  604: "דרום",
  605: "הגליל",
  606: "המשולש",
  607: "הנגב",
  608: "השפלה",
  609: "השרון",
  610: "חבל לכיש",
  611: "חדרה מורדות הכרמל",
  612: "חיפה והקריות",
  619: "ירושלים והסביבה",
  613: "עמק בית שאן",
  614: "עמק החולה",
  615: "עמק הירדן",
  616: "עמק יזרעאל",
  617: "צפון",
  618: "רמת הגולן",
};

export const JobExtentList = {
  fulltime: "משרה מלאה",
  parttime: "משרה חלקית",
  temporary: "משרה זמנית",
};

export const JobExtentListModified = [
  { id: 0, name: "משרה מלאה", value: "fulltime" },
  { id: 1, name: "משרה חלקית", value: "parttime" },
  { id: 2, name: "משרה זמנית", value: "temporary" },
];

export const SkillLevels = [
  { id: 0, label: "ללא ידע כלל", value: "none" },
  { id: 1, label: "בסיסית", value: "basic" },
  { id: 2, label: "ממוצעת", value: "average" },
  { id: 3, label: "מתקדם", value: "advanced" },
  { id: 4, label: "מומחה", value: "expert" },
];

export function translateSkillLevel(value) {
  const index = SkillLevels.findIndex((level) => level.value === value);
  if (index >= 0) return SkillLevels[index].label;
  return "";
}

export const LanguageLevels = [
  { id: 0, label: "ללא שליטה בשפה", value: "none" },
  { id: 1, label: "בסיסית", value: "basic" },
  { id: 2, label: "ממוצעת", value: "average" },
  { id: 3, label: "טובה", value: "good" },
  { id: 4, label: "טובה מאוד", value: "veryGood" },
  { id: 5, label: "שפת אם", value: "motherTounge" },
];

export const JobActionsMenuOptions = [
  { id: "editJob", label: "ערוך משרה" },
  { id: "uploadCandidates", label: "העלה מועמדים" },
  { id: "duplicate", label: "שכפל משרה" },
  { id: "archive", label: "העבר לארכיב" },
  { id: "closeJob", label: "סגירת משרה" },
  { id: "report", label: 'הצג דו"ח' },
];

export const JobDeclinedReasonsOptions = [
  { id: "salary", label: "השכר אינו מתאים לי" },
  { id: "location", label: "מיקום המשרה אינו מתאים לי" },
  { id: "jobRequirements", label: "דרישות המשרה אינן מתאימות לי" },
  { id: "employed", label: "אני כבר מועסק/ת" },
  { id: "other", label: "אחר" },
];

export const UnsubscribeReasons = [
  { id: "alreadyEmployed", label: "אני כבר מועסק/ת" },
  { id: "signUp", label: "מעולם לא נרשמתי אצלכם" },
  { id: "unqualifiedJobs", label: "המשרות שלכם אינן מתאימות לי" },
  { id: "other", label: "אחר" },
];

export const answersValues = [
  { id: 1, label: "ללא ניסיון", value: "0" },
  { id: 2, label: "עד שנה", value: "1" },
  { id: 3, label: "עד שנתיים", value: "2" },
  { id: 4, label: "3-4 שנים", value: "3-4" },
  { id: 5, label: "חמש שנים או יותר", value: "5+" },
];

export const dateFilters = [
  { label: "כל הזמנים", id: "alltimes" },
  { label: "7 הימים האחרונים", id: "lastSevenDays" },
  { label: "14 הימים האחרונים", id: "lastFourteenDays" },
  { label: "מתחילת החודש", id: "thisMonth" },
  { label: "חודש קודם", id: "lastMonth" },
  { label: "מתחילת השנה", id: "thisYear" },
];

export const CitiesList = [
  { id: 1331, name: "כמאנה" },
  { id: 3826, name: "שער שומרון" },
  { id: 1347, name: "קצר א-סר" },
  { id: 3400, name: "חברון" },
  { id: 3777, name: "סנסנה" },
  { id: 1220, name: "אבירים" },
  { id: 472, name: "אבו גוש" },
  { id: 1342, name: "אבו קרינאת" },
  { id: 473, name: "אבו סנאן" },
  { id: 1375, name: "אבו תלול" },
  { id: 1068, name: "אדמית" },
  { id: 2035, name: "עדנים" },
  { id: 1123, name: "אדרת" },
  { id: 113, name: "אדירים" },
  { id: 1199, name: "עדי" },
  { id: 3759, name: "אדורה" },
  { id: 313, name: "אפק" },
  { id: 4301, name: "אפיק" },
  { id: 176, name: "אפיקים" },
  { id: 7700, name: "עפולה" },
  { id: 794, name: "עגור" },
  { id: 1157, name: "אחווה" },
  { id: 797, name: "אחיעזר" },
  { id: 785, name: "אחיהוד" },
  { id: 804, name: "אחיסמך" },
  { id: 850, name: "אחיטוב" },
  { id: 821, name: "אחוזם" },
  { id: 1330, name: "אחוזת ברק" },
  { id: 7600, name: "עכו" },
  { id: 1359, name: "אל סייד" },
  { id: 1316, name: "אל-עריאן" },
  { id: 1339, name: "אל-עזי" },
  { id: 3727, name: "עלי זהב" },
  { id: 3750, name: "אלפי מנשה" },
  { id: 1182, name: "אלון הגליל" },
  { id: 3604, name: "אלון שבות" },
  { id: 429, name: "אלוני אבא" },
  { id: 4017, name: "אלוני הבשן" },
  { id: 868, name: "אלוני יצחק" },
  { id: 285, name: "אלונים" },
  { id: 688, name: "עלמה" },
  { id: 1125, name: "אלמגור" },
  { id: 3556, name: "אלמוג" },
  { id: 3715, name: "עלמון" },
  { id: 1146, name: "עלומים" },
  { id: 1145, name: "אלומה" },
  { id: 330, name: "אלומות" },
  { id: 23, name: "אמציה" },
  { id: 319, name: "עמיר" },
  { id: 1064, name: "אמירים" },
  { id: 385, name: "עמיעד" },
  { id: 318, name: "עמיעוז" },
  { id: 3824, name: "עמיחי" },
  { id: 779, name: "עמינדב" },
  { id: 773, name: "עמיקם" },
  { id: 1253, name: "אמנון" },
  { id: 708, name: "עמקה" },
  { id: 10001, name: "עמק חפר" }, //New insert, id: 10001
  { id: 1212, name: "עמוקה" },
  { id: 4012, name: "אניעם" },
  { id: 637, name: "ערערה" },
  { id: 1192, name: "ערערה-בנגב" },
  { id: 2560, name: "ערד" },
  { id: 1246, name: "עראמשה" },
  { id: 701, name: "ארבל" },
  { id: 3598, name: "ארגמן" },
  { id: 3570, name: "אריאל" },
  { id: 1335, name: "ערב אל נעים" },
  { id: 531, name: "עראבה" },
  { id: 1324, name: "ארסוף" },
  { id: 593, name: "ערוגות" },
  { id: 3754, name: "אספר" },
  { id: 591, name: "עשרת" },
  { id: 1152, name: "אשלים" },
  { id: 70, name: "אשדוד" },
  { id: 199, name: "אשדות יעקב (איחוד)" },
  { id: 188, name: "אשדות יעקב (מאוחד)" },
  { id: 1256, name: "אשרת" },
  { id: 7100, name: "אשקלון" },
  { id: 3658, name: "עטרת" },
  { id: 53, name: "עתלית" },
  { id: 917, name: "עצמון שגב" },
  { id: 892, name: "עבדון" },
  { id: 3786, name: "אבנת" },
  { id: 679, name: "אביאל" },
  { id: 1070, name: "אביעזר" },
  { id: 819, name: "אביגדור" },
  { id: 175, name: "אביחיל" },
  { id: 2052, name: "אביטל" },
  { id: 1115, name: "אביבים" },
  { id: 4011, name: "אבני איתן" },
  { id: 3793, name: "אבני חפץ" },
  { id: 1311, name: "אבשלום" },
  { id: 1275, name: "אבטליון" },
  { id: 156, name: "עיינות" },
  { id: 77, name: "איילת השחר" },
  { id: 711, name: "עזריה" },
  { id: 565, name: "אזור" },
  { id: 837, name: "עזריאל" },
  { id: 817, name: "עזריקם" },
  { id: 2043, name: "בחן" },
  { id: 94, name: "בלפוריה" },
  { id: 6000, name: "באקה אל-גרביה" },
  { id: 823, name: "בר גיורא" },
  { id: 1191, name: "בר יוחאי" },
  { id: 667, name: "ברעם" },
  { id: 141, name: "ברק" },
  { id: 2038, name: "ברקת" },
  { id: 3654, name: "ברקן" },
  { id: 617, name: "ברקאי" },
  { id: 1326, name: 'בסמ"ה' },
  { id: 944, name: "בסמת טבעון" },
  { id: 3794, name: "בת עין" },
  { id: 1323, name: "בת הדר" },
  { id: 1319, name: "בת חפר" },
  { id: 1361, name: "בת חן" },
  { id: 33, name: "בת שלמה" },
  { id: 6200, name: "בת ים" },
  { id: 389, name: "בצרה" },
  { id: 1278, name: "באר מילכה" },
  { id: 21, name: "באר אורה" },
  { id: 9000, name: "באר שבע" },
  { id: 155, name: "באר טוביה" },
  { id: 2530, name: "באר יעקב" },
  { id: 399, name: "בארי" },
  { id: 450, name: "בארות יצחק" },
  { id: 697, name: "בארותיים" },
  { id: 1376, name: "באר גנים" },
  { id: 480, name: "בית ג'ן" },
  { id: 712, name: "בן עמי" },
  { id: 2013, name: "בן שמן (מושב)" },
  { id: 1084, name: "בן שמן (כפר נוער)" },
  { id: 760, name: "בן זכאי" },
  { id: 685, name: "בניה" },
  { id: 448, name: "בני עטרות" },
  { id: 1066, name: 'בני עי"ש' },
  { id: 6100, name: "בני ברק" },
  { id: 592, name: "בני דרום" },
  { id: 386, name: "בני דרור" },
  { id: 1363, name: "בני נצרים" },
  { id: 588, name: "בני ראם" },
  { id: 4015, name: "בני יהודה" },
  { id: 418, name: "בני ציון" },
  { id: 3612, name: "בקעות" },
  { id: 864, name: "בקוע" },
  { id: 3710, name: "ברכה" },
  { id: 746, name: "ברכיה" },
  { id: 428, name: "ברור חיל" },
  { id: 2060, name: "ברוש" },
  { id: 95, name: "בית אלפא" },
  { id: 604, name: "בית עריף" },
  { id: 3652, name: "בית אריה" },
  { id: 1076, name: "בית ברל" },
  { id: 466, name: "בית דגן" },
  { id: 3574, name: "בית אל" },
  { id: 562, name: "בית אלעזרי" },
  { id: 756, name: "בית עזרא" },
  { id: 571, name: "בית גמליאל" },
  { id: 619, name: "בית גוברין" },
  { id: 3645, name: "בית הערבה" },
  { id: 572, name: "בית העמק" },
  { id: 723, name: "בית הגדי" },
  { id: 373, name: "בית הלוי" },
  { id: 159, name: "בית חנן" },
  { id: 800, name: "בית חנניה" },
  { id: 242, name: "בית השיטה" },
  { id: 1050, name: "בית חשמונאי" },
  { id: 877, name: "בית חירות" },
  { id: 322, name: "בית הלל" },
  { id: 2033, name: "בית חלקיה" },
  { id: 3575, name: "בית חורון" },
  { id: 430, name: "בית לחם הגלילית" },
  { id: 751, name: "בית מאיר" },
  { id: 784, name: "בית נחמיה" },
  { id: 672, name: "בית נקופה" },
  { id: 16, name: "בית ניר" },
  { id: 317, name: "בית אורן" },
  { id: 202, name: "בית עובד" },
  { id: 598, name: "בית קמה" },
  { id: 365, name: "בית קשת" },
  { id: 848, name: "בית רבן" },
  { id: 1162, name: "בית רימון" },
  { id: 9200, name: "בית שאן" },
  { id: 248, name: "בית שערים" },
  { id: 2610, name: "בית שמש" },
  { id: 747, name: "בית שקמה" },
  { id: 301, name: "בית עוזיאל" },
  { id: 200, name: "בית ינאי" },
  { id: 288, name: "בית יהושע" },
  { id: 326, name: "בית יצחק-שער חפר" },
  { id: 265, name: "בית יוסף" },
  { id: 710, name: "בית זית" },
  { id: 353, name: "בית זיד" },
  { id: 143, name: "בית זרע" },
  { id: 212, name: "בית צבי" },
  { id: 3780, name: "ביתר עילית" },
  { id: 589, name: "בצת" },
  { id: 483, name: "בענה" },
  { id: 9800, name: "בנימינה-גבעת עדה" },
  { id: 998, name: "ביר אל-מכסור" },
  { id: 1348, name: "ביר הדאג'" },
  { id: 368, name: "ביריה" },
  { id: 252, name: "ביתן אהרן" },
  { id: 762, name: "בטחה" },
  { id: 234, name: "ביצרון" },
  { id: 1368, name: "בני דקלים" },
  { id: 3744, name: "ברוכין" },
  { id: 482, name: "בועיינה-נוג'ידאת" },
  { id: 4001, name: "בוקעאתא" },
  { id: 698, name: "בורגתה" },
  { id: 559, name: "בוסתן הגליל" },
  { id: 489, name: "דבוריה" },
  { id: 302, name: "דפנה" },
  { id: 475, name: "דחי" },
  { id: 494, name: "דאלית אל-כרמל" },
  { id: 300, name: "דליה" },
  { id: 431, name: "דלתון" },
  { id: 303, name: "דן" },
  { id: 407, name: "דברת" },
  { id: 62, name: "דגניה א" },
  { id: 79, name: "דגניה ב" },
  { id: 490, name: "דייר אל-אסד" },
  { id: 492, name: "דייר חנא" },
  { id: 493, name: "דייר ראפאת" },
  { id: 1317, name: "דמיידה" },
  { id: 1241, name: "דקל" },
  { id: 849, name: "דבירה" },
  { id: 146, name: "דבורה" },
  { id: 2200, name: "דימונה" },
  { id: 2063, name: "דישון" },
  { id: 3747, name: "דולב" },
  { id: 738, name: "דור" },
  { id: 336, name: "דורות" },
  { id: 1067, name: 'דוב"ב' },
  { id: 3650, name: "אפרת" },
  { id: 530, name: "עיילבון" },
  { id: 546, name: "עין אל-אסד" },
  { id: 1320, name: "עין חוד" },
  { id: 532, name: "עין מאהל" },
  { id: 521, name: "עין נקובא" },
  { id: 4502, name: "עין קנייא" },
  { id: 514, name: "עין ראפה" },
  { id: 1309, name: "אלעד" },
  { id: 3618, name: "אלעזר" },
  { id: 4003, name: "אל-רום" },
  { id: 2600, name: "אילת" },
  { id: 3765, name: "עלי" },
  { id: 4002, name: "אלי-עד" },
  { id: 1365, name: "אליאב" },
  { id: 1248, name: "אליפז" },
  { id: 730, name: "אליפלט" },
  { id: 841, name: "אלישמע" },
  { id: 294, name: "אילון" },
  { id: 3579, name: "אלון מורה" },
  { id: 1126, name: "אילות" },
  { id: 3560, name: "אלקנה" },
  { id: 603, name: "אלקוש" },
  { id: 41, name: "אליכין" },
  { id: 682, name: "אליקים" },
  { id: 204, name: "אלישיב" },
  { id: 772, name: "אמונים" },
  { id: 687, name: "עין איילה" },
  { id: 436, name: "עין דור" },
  { id: 2042, name: "עין גדי" },
  { id: 273, name: "עין גב" },
  { id: 1240, name: "עין הבשור" },
  { id: 367, name: "עין העמק" },
  { id: 167, name: "עין החורש" },
  { id: 289, name: "עין המפרץ" },
  { id: 383, name: 'עין הנצי"ב' },
  { id: 89, name: "עין חרוד (איחוד)" },
  { id: 82, name: "עין חרוד (מאוחד)" },
  { id: 676, name: "עין השלושה" },
  { id: 270, name: "עין השופט" },
  { id: 1053, name: "עין חצבה" },
  { id: 74, name: "עין הוד" },
  { id: 223, name: "עין עירון" },
  { id: 1056, name: 'עין כרם-בי"ס חקלאי' },
  { id: 426, name: "עין כרמל" },
  { id: 880, name: "עין שריד" },
  { id: 139, name: "עין שמר" },
  { id: 1251, name: "עין תמר" },
  { id: 157, name: "עין ורד" },
  { id: 813, name: "עין יעקב" },
  { id: 806, name: "עין יהב" },
  { id: 4503, name: "עין זיוון" },
  { id: 622, name: "עין צורים" },
  { id: 871, name: "עינת" },
  { id: 3712, name: "ענב" },
  { id: 714, name: "ארז" },
  { id: 71, name: "אשבול" },
  { id: 2021, name: "אשל הנשיא" },
  { id: 1188, name: "אשחר" },
  { id: 3722, name: "אשכולות" },
  { id: 740, name: "אשתאול" },
  { id: 37, name: "איתן" },
  { id: 886, name: "איתנים" },
  { id: 1298, name: "אתגר" },
  { id: 1081, name: "אבן מנחם" },
  { id: 783, name: "אבן ספיר" },
  { id: 400, name: "אבן שמואל" },
  { id: 182, name: "אבן יהודה" },
  { id: 369, name: "גלעד (אבן יצחק)" },
  { id: 376, name: "עברון" },
  { id: 716, name: "אייל" },
  { id: 1149, name: "עזר" },
  { id: 328, name: "עזוז" },
  { id: 535, name: "פסוטה" },
  { id: 537, name: "פוריידיס" },
  { id: 842, name: "געש" },
  { id: 463, name: "געתון" },
  { id: 145, name: "גדיש" },
  { id: 35, name: "גדות" },
  { id: 393, name: "גלאון" },
  { id: 1072, name: "גן הדרום" },
  { id: 225, name: "גן השומרון" },
  { id: 239, name: "גן חיים" },
  { id: 1274, name: "גן נר" },
  { id: 144, name: "גן שלמה" },
  { id: 72, name: "גן שמואל" },
  { id: 311, name: "גן שורק" },
  { id: 166, name: "גן יבנה" },
  { id: 734, name: "גן יאשיה" },
  { id: 218, name: "גני עם" },
  { id: 1103, name: "גני הדר" },
  { id: 3823, name: "גני מודיעין" },
  { id: 1371, name: "גני טל" },
  { id: 229, name: "גני תקווה" },
  { id: 862, name: "גני יוחנן" },
  { id: 836, name: "גנות" },
  { id: 549, name: "גנות הדר" },
  { id: 128, name: "גת רימון" },
  { id: 340, name: "גת" },
  { id: 457, name: "גזית" },
  { id: 706, name: "גיאה" },
  { id: 853, name: "גאליה" },
  { id: 872, name: "גאולי תימן" },
  { id: 379, name: "גאולים" },
  { id: 2550, name: "גדרה" },
  { id: 39, name: "גפן" },
  { id: 346, name: "גליל ים" },
  { id: 1129, name: "גרופית" },
  { id: 305, name: "גשר" },
  { id: 574, name: "גשר הזיו" },
  { id: 4022, name: "גשור" },
  { id: 86, name: "גבע" },
  { id: 683, name: "גבע כרמל" },
  { id: 3763, name: "גבע בנימין" },
  { id: 1344, name: "גבעות בר" },
  { id: 342, name: "גברעם" },
  { id: 133, name: "גבת" },
  { id: 424, name: "גבים" },
  { id: 352, name: "גבולות" },
  { id: 370, name: "גזר" },
  { id: 196, name: "גיבתון" },
  { id: 442, name: "גדעונה" },
  { id: 736, name: "גילת" },
  { id: 3606, name: "גלגל" },
  { id: 1204, name: "גילון" },
  { id: 745, name: "גמזו" },
  { id: 863, name: "גינתון" },
  { id: 92, name: "גיניגר" },
  { id: 262, name: "גינוסר" },
  { id: 1206, name: "גיתה" },
  { id: 3613, name: "גיתית" },
  { id: 1293, name: "גבעת אבני" },
  { id: 147, name: "גבעת ברנר" },
  { id: 1288, name: "גבעת אלה" },
  { id: 870, name: "גבעת השלושה" },
  { id: 2018, name: "גבעת חיים (איחוד)" },
  { id: 173, name: "גבעת חיים (מאוחד)" },
  { id: 207, name: 'גבעת ח"ן' },
  { id: 802, name: 'גבעת כ"ח' },
  { id: 360, name: 'גבעת ניל"י' },
  { id: 703, name: "גבעת עוז" },
  { id: 1077, name: "גבעת שפירא" },
  { id: 566, name: "גבעת שמש" },
  { id: 681, name: "גבעת שמואל" },
  { id: 787, name: "גבעת יערים" },
  { id: 919, name: "גבעת ישעיהו" },
  { id: 4021, name: "גבעת יואב" },
  { id: 3730, name: "גבעת זאב" },
  { id: 6300, name: "גבעתיים" },
  { id: 793, name: "גבעתי" },
  { id: 2014, name: "גבעולים" },
  { id: 3644, name: "גבעון החדשה" },
  { id: 1362, name: "גבעות עדן" },
  { id: 1043, name: "גיזו" },
  { id: 852, name: "גונן" },
  { id: 755, name: "גורן" },
  { id: 1219, name: "גורנות הגליל" },
  { id: 675, name: "הבונים" },
  { id: 4026, name: "חד-נס" },
  { id: 191, name: "הדר עם" },
  { id: 6500, name: "חדרה" },
  { id: 618, name: "חדיד" },
  { id: 363, name: "חפץ חיים" },
  { id: 3764, name: "חגי" },
  { id: 717, name: "חגור" },
  { id: 356, name: "הגושרים" },
  { id: 434, name: "החותרים" },
  { id: 4000, name: "חיפה" },
  { id: 1272, name: "חלוץ" },
  { id: 377, name: "המעפיל" },
  { id: 343, name: "חמדיה" },
  { id: 993, name: "חמאם" },
  { id: 3609, name: "חמרה" },
  { id: 280, name: "חניתה" },
  { id: 1257, name: "חנתון" },
  { id: 807, name: "חניאל" },
  { id: 423, name: "העוגן" },
  { id: 702, name: "האון" },
  { id: 3769, name: "הר אדר" },
  { id: 1261, name: "הר עמשא" },
  { id: 3603, name: "הר גילה" },
  { id: 464, name: "הראל" },
  { id: 1203, name: "הררית" },
  { id: 1209, name: "חרשים" },
  { id: 1249, name: "הרדוף" },
  { id: 1247, name: "חריש" },
  { id: 1024, name: "חרוצים" },
  { id: 3770, name: "חשמונאים" },
  { id: 677, name: "הסוללים" },
  { id: 4005, name: "חספין" },
  { id: 235, name: "חבצלת השרון" },

  { id: 684, name: "היוגב" },
  { id: 700, name: "חצב" },
  { id: 397, name: "חצרים" },
  { id: 13, name: "חצבה" },
  { id: 1047, name: "חזון" },
  { id: 2034, name: "חצור הגלילית" },
  { id: 406, name: "חצור-אשדוד" },
  { id: 307, name: "הזורעים" },
  { id: 250, name: "הזורע" },
  { id: 90, name: "חפצי-בה" },
  { id: 820, name: "חלץ" },
  { id: 801, name: "חמד" },
  { id: 422, name: "חרב לאת" },
  { id: 3717, name: "חרמש" },
  { id: 162, name: "חירות" },
  { id: 6400, name: "הרצליה" },
  { id: 1110, name: "חבר" },
  { id: 219, name: "חיבת ציון" },
  { id: 1208, name: "הילה" },
  { id: 3643, name: "חיננית" },
  { id: 9700, name: "הוד השרון" },
  { id: 1322, name: "הודיות" },
  { id: 726, name: "הודיה" },
  { id: 115, name: "חופית" },
  { id: 205, name: "חגלה" },
  { id: 1239, name: "חולית" },
  { id: 6600, name: "חולון" },
  { id: 355, name: "חורשים" },
  { id: 662, name: "חוסן" },
  { id: 1186, name: "הושעיה" },
  { id: 253, name: "חולתה" },
  { id: 160, name: "חולדה" },
  { id: 374, name: "חוקוק" },
  { id: 1303, name: "חורה" },
  { id: 496, name: "חורפיש" },
  { id: 1332, name: "חוסנייה" },

  { id: 529, name: "אעבלין" },
  { id: 338, name: "איבים" },
  { id: 652, name: "אבטין" },
  { id: 1175, name: "עידן" },
  { id: 478, name: "אכסאל" },
  { id: 49, name: "אילניה" },
  { id: 511, name: "עילוט" },
  { id: 3660, name: "עמנואל" },
  { id: 1187, name: "עיר אובות" },
  { id: 1336, name: "אירוס" },
  { id: 534, name: "עספיא" },
  { id: 3762, name: "איתמר" },
  { id: 628, name: "ג'ת" },
  { id: 627, name: "ג'לג'וליה" },
  { id: 3000, name: "ירושלים" },
  { id: 541, name: "ג'סר א-זרקא" },
  { id: 1292, name: "ג'דיידה-מכר" },
  { id: 485, name: "ג'ולס" },
  { id: 576, name: "כברי" },
  { id: 504, name: "כאבול" },
  { id: 1338, name: "כדיתה" },
  { id: 371, name: "כדורי" },
  { id: 633, name: "כפר ברא" },
  { id: 508, name: "כפר כמא" },
  { id: 509, name: "כפר כנא" },
  { id: 510, name: "כפר מנדא" },
  { id: 512, name: "כפר מצר" },
  { id: 654, name: "כפר קרע" },
  { id: 634, name: "כפר קאסם" },
  { id: 507, name: "כפר יאסיף" },
  { id: 1210, name: "כחל" },
  { id: 1229, name: "כלנית" },
  { id: 1201, name: "כמון" },
  { id: 4028, name: "כנף" },
  { id: 2006, name: "כנות" },
  { id: 38, name: "כרי דשא" },
  { id: 1285, name: "כרכום" },
  { id: 1374, name: "כרמי קטיף" },
  { id: 1264, name: "כרמי יוסף" },
  { id: 3766, name: "כרמי צור" },
  { id: 3656, name: "כרמל" },
  { id: 1139, name: "כרמיאל" },
  { id: 768, name: "כרמיה" },
  { id: 3638, name: "כפר אדומים" },
  { id: 690, name: "כפר אחים" },
  { id: 857, name: "כפר אביב" },
  { id: 875, name: "כפר עבודה" },
  { id: 845, name: "כפר עזה" },
  { id: 132, name: "כפר ברוך" },
  { id: 220, name: "כפר ביאליק" },
  { id: 177, name: 'כפר ביל"ו' },
  { id: 2010, name: "כפר בן נון" },
  { id: 357, name: "כפר בלום" },
  { id: 707, name: "כפר דניאל" },
  { id: 3488, name: "כפר עציון" },
  { id: 427, name: "כפר גלים" },
  { id: 106, name: "כפר גדעון" },
  { id: 76, name: "כפר גלעדי" },
  { id: 310, name: "כפר גליקסון" },
  { id: 696, name: 'כפר חב"ד' },
  { id: 192, name: "כפר החורש" },
  { id: 254, name: "כפר המכבי" },
  { id: 582, name: "כפר הנגיד" },
  { id: 1297, name: "כפר חנניה" },
  { id: 443, name: "כפר הנשיא" },
  { id: 890, name: "כפר הנוער הדתי" },
  { id: 3796, name: "כפר האורנים" },
  { id: 888, name: 'כפר הרי"ף' },
  { id: 217, name: 'כפר הרא"ה' },
  { id: 4004, name: "כפר חרוב" },
  { id: 112, name: "כפר חסידים א" },
  { id: 889, name: "כפר חסידים ב" },
  { id: 193, name: "כפר חיים" },
  { id: 187, name: "כפר הס" },
  { id: 255, name: "כפר חיטים" },
  { id: 609, name: "כפר חושן" },
  { id: 388, name: "כפר קיש" },
  { id: 98, name: 'כפר מל"ל' },
  { id: 297, name: "כפר מסריק" },
  { id: 1095, name: "כפר מימון" },
  { id: 274, name: "כפר מנחם" },
  { id: 387, name: "כפר מונש" },
  { id: 764, name: "כפר מרדכי" },
  { id: 316, name: "כפר נטר" },
  { id: 189, name: "כפר פינס" },
  { id: 579, name: "כפר ראש הנקרה" },
  { id: 1130, name: "זרעית" },
  { id: 295, name: "כפר רופין" },
  { id: 1166, name: "כפר רות" },
  { id: 6900, name: "כפר סבא" },
  { id: 605, name: "כפר שמאי" },
  { id: 267, name: "כפר שמריהו" },
  { id: 743, name: "כפר שמואל" },
  { id: 107, name: "כפר סילבר" },
  { id: 249, name: "כפר סירקין" },
  { id: 345, name: "כפר סאלד" },
  { id: 3572, name: "כפר תפוח" },
  { id: 47, name: "כפר תבור" },
  { id: 673, name: "כפר טרומן" },
  { id: 364, name: "כפר אוריה" },
  { id: 190, name: "כפר ויתקין" },
  { id: 320, name: "כפר ורבורג" },
  { id: 1263, name: "כפר ורדים" },
  { id: 170, name: "כפר יעבץ" },
  { id: 85, name: "כפר יחזקאל" },
  { id: 140, name: "כפר יהושע" },
  { id: 168, name: "כפר יונה" },
  { id: 786, name: "כפר זיתים" },
  { id: 1325, name: "כפר זוהרים" },
  { id: 1183, name: "כליל" },
  { id: 1291, name: "כמהין" },
  { id: 1198, name: "כרמים" },
  { id: 88, name: "כרם בן שמן" },
  { id: 664, name: "כרם בן זמרה" },
  { id: 580, name: 'כרם מהר"ל' },
  { id: 1085, name: "כרם שלום" },
  { id: 859, name: "כסלון" },
  { id: 63, name: "כנרת" },
  { id: 1153, name: "כישור" },
  { id: 1296, name: "כסרא-סמיע" },
  { id: 840, name: "כיסופים" },
  { id: 1367, name: "כחלה" },
  { id: 3564, name: "כוכב השחר" },
  { id: 824, name: "כוכב מיכאל" },
  { id: 3779, name: "כוכב יעקב" },
  { id: 1224, name: "כוכב יאיר" },
  { id: 1252, name: "כורזים" },
  { id: 1059, name: "כסיפה" },
  { id: 2023, name: "להב" },
  { id: 380, name: "להבות הבשן" },
  { id: 715, name: "להבות חביבה" },
  { id: 24, name: "לכיש" },
  { id: 1310, name: "לפיד" },
  { id: 1173, name: "לפידות" },
  { id: 1060, name: "לקיה" },
  { id: 585, name: "לביא" },
  { id: 1207, name: "לבון" },
  { id: 1271, name: "להבים" },
  { id: 1114, name: "שריגים" },
  { id: 674, name: "לימן" },
  { id: 1230, name: "לבנים" },
  { id: 7000, name: "לוד" },
  { id: 595, name: "לוחמי הגיטאות" },
  { id: 1255, name: "לוטן" },
  { id: 1171, name: "לוטם" },
  { id: 52, name: "לוזית" },
  { id: 678, name: "מעגן" },
  { id: 694, name: "מעגן מיכאל" },
  { id: 3616, name: "מעלה אדומים" },
  { id: 3653, name: "מעלה עמוס" },
  { id: 3608, name: "מעלה אפרים" },
  { id: 4008, name: "מעלה גמלא" },
  { id: 1127, name: "מעלה גלבוע" },
  { id: 286, name: "מעלה החמישה" },
  { id: 1327, name: "מעלה עירון" },
  { id: 3752, name: "מעלה לבונה" },
  { id: 3651, name: "מעלה מכמש" },
  { id: 1063, name: "מעלות-תרשיחא" },
  { id: 344, name: "מענית" },
  { id: 230, name: "מעש" },
  { id: 197, name: "מעברות" },
  { id: 1082, name: "מעגלים" },
  { id: 3657, name: "מעון" },
  { id: 2055, name: "מאור" },
  { id: 272, name: "מעוז חיים" },
  { id: 416, name: "מעין ברוך" },
  { id: 290, name: "מעין צבי" },
  { id: 1080, name: "מבועים" },
  { id: 695, name: "מגן" },
  { id: 1155, name: "מגן שאול" },
  { id: 375, name: "מגל" },
  { id: 722, name: "מגשימים" },
  { id: 308, name: "מחניים" },
  { id: 1262, name: "צוקים" },
  { id: 1411, name: "מחנה הילה" },
  { id: 1414, name: "מחנה מרים" },
  { id: 1418, name: "מחנה טלי" },
  { id: 1412, name: "מחנה תל נוף" },
  { id: 1415, name: "מחנה יפה" },
  { id: 1196, name: "מחנה יתיר" },
  { id: 1413, name: "מחנה יהודית" },
  { id: 1416, name: "מחנה יוכבד" },
  { id: 776, name: "מחסיה" },
  { id: 516, name: "מג'ד אל-כרום" },
  { id: 4201, name: "מג'דל שמס" },
  { id: 1343, name: "מכחול" },
  { id: 1154, name: "מלכישוע" },
  { id: 596, name: "מלכיה" },
  { id: 1174, name: "מנוף" },
  { id: 1205, name: "מנות" },
  { id: 994, name: "מנשית זבדה" },
  { id: 843, name: "מרגליות" },
  { id: 4203, name: "מסעדה" },
  { id: 939, name: "מסעודין אל-עזאזמה" },
  { id: 421, name: "משאבי שדה" },
  { id: 791, name: "משען" },
  { id: 3785, name: "משכיות" },
  { id: 748, name: "מסלול" },
  { id: 1258, name: "מסד" },
  { id: 263, name: "מסדה" },
  { id: 3605, name: "משואה" },
  { id: 620, name: "משואות יצחק" },
  { id: 822, name: "מטע" },
  { id: 1315, name: "מתן" },
  { id: 1184, name: "מתת" },
  { id: 3648, name: "מתתיהו" },
  { id: 573, name: "מבקיעים" },
  { id: 28, name: "מזכרת בתיה" },
  { id: 757, name: "מצליח" },
  { id: 606, name: "מזור" },
  { id: 517, name: "מזרעה" },
  { id: 325, name: "מצובה" },
  { id: 1128, name: "מי עמי" },
  { id: 102, name: "מאיר שפיה" },
  { id: 570, name: "מעונה" },
  { id: 668, name: "מפלסים" },
  { id: 689, name: "מגדים" },
  { id: 586, name: "מגידו" },
  { id: 3599, name: "מחולה" },
  { id: 649, name: "מייסר" },
  { id: 3614, name: "מכורה" },
  { id: 164, name: "מלאה" },
  { id: 2044, name: "מלילות" },
  { id: 48, name: "מנחמיה" },
  { id: 347, name: "מנרה" },
  { id: 2030, name: "מנוחה" },
  { id: 1282, name: "מירב" },
  { id: 1340, name: "מרחב עם" },
  { id: 97, name: "מרחביה" },
  { id: 66, name: "מרחביה" },
  { id: 1098, name: "מרכז שפירא" },
  { id: 4101, name: "מרום גולן" },
  { id: 607, name: "מירון" },
  { id: 731, name: "מישר" },
  { id: 520, name: "משהד" },
  { id: 742, name: "מסילת ציון" },
  { id: 298, name: "מסילות" },
  { id: 1268, name: "מיתר" },
  { id: 2054, name: "מיטב" },
  { id: 43, name: "מטולה" },
  { id: 1015, name: "מבשרת ציון" },
  { id: 771, name: "מבוא ביתר" },
  { id: 3569, name: "מבוא דותן" },
  { id: 4204, name: "מבוא חמה" },
  { id: 3709, name: "מבוא חורון" },
  { id: 1141, name: "מבוא מודיעים" },
  { id: 1318, name: "מבואות ים" },
  { id: 3825, name: "מבואות יריחו" },
  { id: 3745, name: "מצדות יהודה" },
  { id: 4019, name: "מיצר" },
  { id: 648, name: "מצר" },
  { id: 518, name: "מעיליא" },
  { id: 2029, name: "מדרך עוז" },
  { id: 1140, name: "מדרשת בן גוריון" },
  { id: 897, name: "מדרשת רופין" },
  { id: 65, name: "מגדל" },
  { id: 874, name: "מגדל העמק" },
  { id: 3561, name: "מגדל עוז" },
  { id: 3751, name: "מגדלים" },
  { id: 1202, name: "מכמנים" },
  { id: 382, name: "מכמורת" },
  { id: 22, name: "מקווה ישראל" },
  { id: 378, name: "משגב עם" },
  { id: 765, name: "משגב דב" },
  { id: 670, name: "משמר איילון" },
  { id: 563, name: "משמר דוד" },
  { id: 130, name: "משמר העמק" },
  { id: 395, name: "משמר הנגב" },
  { id: 194, name: "משמר השרון" },
  { id: 729, name: "משמר השבעה" },
  { id: 732, name: "משמר הירדן" },
  { id: 213, name: "משמרות" },
  { id: 425, name: "משמרת" },
  { id: 1370, name: "מצפה אילן" },
  { id: 829, name: "מבטחים" },
  { id: 58, name: "מצפה" },
  { id: 1222, name: 'מצפה אבי"ב' },
  { id: 1190, name: "מצפה נטופה" },
  { id: 99, name: "מצפה רמון" },
  { id: 3610, name: "מצפה שלם" },
  { id: 3576, name: "מצפה יריחו" },
  { id: 104, name: "מזרע" },
  { id: 3797, name: "מודיעין עילית" },
  { id: 1200, name: "מודיעין-מכבים-רעות" },
  { id: 269, name: "מולדת" },
  { id: 1163, name: "מורן" },
  { id: 1178, name: "מורשת" },
  { id: 208, name: "מוצא עילית" },
  { id: 481, name: "מגאר" },
  { id: 635, name: "מוקייבלה" },
  { id: 3787, name: "נעלה" },
  { id: 158, name: "נען" },
  { id: 3620, name: "נערן" },
  { id: 524, name: "נאעורה" },
  { id: 3713, name: 'נעמ"ה' },
  { id: 1276, name: "אשבל" },
  { id: 3646, name: "חמדת" },
  { id: 844, name: "נחל עוז" },
  { id: 1267, name: "שיטים" },
  { id: 2045, name: "נחלה" },
  { id: 80, name: "נהלל" },
  { id: 3767, name: "נחליאל" },
  { id: 809, name: "נחם" },
  { id: 9100, name: "נהריה" },
  { id: 522, name: "נחף" },
  { id: 433, name: "נחשולים" },
  { id: 777, name: "נחשון" },
  { id: 705, name: "נחשונים" },

  { id: 1254, name: "נטף" },
  { id: 4014, name: "נטור" },
  { id: 1366, name: "נווה" },
  { id: 7300, name: "נצרת" },
  { id: 4551, name: "נאות גולן" },
  { id: 1124, name: "נאות הכיכר" },
  { id: 408, name: "נאות מרדכי" },
  { id: 186, name: "נעורים" },
  { id: 315, name: "נגבה" },
  { id: 3724, name: "נגוהות" },
  { id: 449, name: "נחלים" },
  { id: 309, name: "נהורה" },
  { id: 59, name: "נחושה" },
  { id: 523, name: "ניין" },
  { id: 1143, name: "נס עמים" },
  { id: 825, name: "נס הרים" },
  { id: 7200, name: "נס ציונה" },
  { id: 2500, name: "נשר" },
  { id: 1369, name: "נטע" },
  { id: 174, name: "נטעים" },
  { id: 7400, name: "נתניה" },
  { id: 1242, name: "נתיב העשרה" },
  { id: 3555, name: "נתיב הגדוד" },
  { id: 693, name: 'נתיב הל"ה' },
  { id: 792, name: "נתיב השיירה" },
  { id: 246, name: "נתיבות" },
  { id: 1147, name: "נטועה" },
  { id: 396, name: "נבטים" },
  { id: 3573, name: "נוה צוף" },
  { id: 4303, name: 'נווה אטי"ב' },
  { id: 926, name: "נווה אבות" },
  { id: 3725, name: "נווה דניאל" },
  { id: 296, name: "נווה איתן" },
  { id: 1279, name: "נווה חריף" },
  { id: 405, name: "נווה אילן" },
  { id: 1071, name: "נווה מיכאל" },
  { id: 827, name: "נווה מבטח" },
  { id: 1259, name: "נווה שלום" },
  { id: 590, name: "נווה אור" },
  { id: 312, name: "נווה ים" },
  { id: 686, name: "נווה ימין" },
  { id: 858, name: "נווה ירק" },
  { id: 1314, name: "נווה זיו" },
  { id: 1057, name: "נווה זוהר" },
  { id: 1372, name: "נצר חזני" },
  { id: 435, name: "נצר סרני" },
  { id: 3655, name: 'ניל"י' },
  { id: 4035, name: "נמרוד" },
  { id: 348, name: "ניר עם" },
  { id: 2048, name: "ניר עקיבא" },
  { id: 553, name: "ניר בנים" },
  { id: 256, name: "ניר דוד" },
  { id: 808, name: "ניר אליהו" },
  { id: 769, name: "ניר עציון" },
  { id: 720, name: "ניר גלים" },
  { id: 11, name: 'ניר ח"ן' },
  { id: 2047, name: "ניר משה" },
  { id: 69, name: "ניר עוז" },
  { id: 165, name: "ניר יפה" },
  { id: 699, name: "ניר ישראל" },
  { id: 402, name: "ניר יצחק" },
  { id: 331, name: "ניר צבי" },
  { id: 602, name: "נירים" },
  { id: 1236, name: "נירית" },
  { id: 351, name: "ניצן" },
  { id: 1419, name: "ניצן ב" },
  { id: 1195, name: "ניצנה" },
  { id: 851, name: "ניצני עוז" },
  { id: 1280, name: "ניצני סיני" },
  { id: 359, name: "ניצנים" },
  { id: 15, name: "נועם" },
  { id: 1333, name: "נוף איילון" },
  { id: 1061, name: "נוף הגליל" },
  { id: 257, name: "נופך" },
  { id: 3790, name: "נופים" },
  { id: 1284, name: "נופית" },
  { id: 55, name: "נוגה" },
  { id: 3726, name: "נוקדים" },
  { id: 447, name: "נורדיה" },
  { id: 4304, name: "נוב" },
  { id: 833, name: "נורית" },
  { id: 4010, name: "אודם" },
  { id: 31, name: "אופקים" },
  { id: 810, name: "עופר" },
  { id: 3617, name: "עופרה" },
  { id: 1046, name: "אוהד" },
  { id: 737, name: "עולש" },
  { id: 1108, name: "אומן" },
  { id: 666, name: "עומר" },
  { id: 680, name: "אומץ" },
  { id: 1020, name: "אור עקיבא" },
  { id: 1294, name: "אור הגנוז" },
  { id: 67, name: "אור הנר" },
  { id: 2400, name: "אור יהודה" },
  { id: 780, name: "אורה" },
  { id: 882, name: "אורנים" },
  { id: 3760, name: "אורנית" },
  { id: 2012, name: "אורות" },
  { id: 4013, name: "אורטל" },
  { id: 3748, name: "עתניאל" },
  { id: 32, name: "עוצם" },
  { id: 2059, name: 'פעמי תש"ז' },
  { id: 597, name: "פלמחים" },
  { id: 1151, name: "פארן" },
  { id: 7800, name: "פרדס חנה-כרכור" },
  { id: 171, name: "פרדסיה" },
  { id: 599, name: "פרוד" },
  { id: 749, name: "פטיש" },
  { id: 838, name: "פדיה" },
  { id: 3768, name: "פדואל" },
  { id: 750, name: "פדויים" },
  { id: 1185, name: "פלך" },
  { id: 3723, name: "פני חבר" },
  { id: 536, name: "פקיעין" },
  { id: 281, name: "פקיעין חדשה" },
  { id: 2053, name: "פרזון" },
  { id: 1231, name: "פרי גן" },
  { id: 3659, name: "פסגות" },
  { id: 7900, name: "פתח תקווה" },
  { id: 839, name: "פתחיה" },
  { id: 3615, name: "פצאל" },
  { id: 767, name: "פורת" },
  { id: 1313, name: "פוריה עילית" },
  { id: 1104, name: "פוריה - כפר עבודה" },
  { id: 1105, name: "פוריה - נווה עובד" },

  { id: 1211, name: "קדרים" },
  { id: 195, name: "קדימה-צורן" },
  { id: 638, name: "קלנסווה" },
  { id: 3601, name: "קליה" },
  { id: 3640, name: "קרני שומרון" },

  { id: 1243, name: "קציר" },
  { id: 4100, name: "קצרין" },
  { id: 3781, name: "קדר" },
  { id: 392, name: "קדמה" },
  { id: 3557, name: "קדומים" },
  { id: 4024, name: "קלע" },
  { id: 414, name: "קלחים" },
  { id: 1167, name: "קיסריה" },
  { id: 4006, name: "קשת" },
  { id: 1052, name: "קטורה" },
  { id: 334, name: "קבוצת יבנה" },
  { id: 4025, name: "קדמת צבי" },
  { id: 615, name: "קדרון" },
  { id: 78, name: "קרית ענבים" },
  { id: 3611, name: "קרית ארבע" },
  { id: 6800, name: "קרית אתא" },
  { id: 9500, name: "קרית ביאליק" },
  { id: 469, name: "קרית עקרון" },
  { id: 2630, name: "קרית גת" },
  { id: 1034, name: "קרית מלאכי" },
  { id: 8200, name: "קרית מוצקין" },
  { id: 3746, name: "קרית נטפים" },
  { id: 2620, name: "קרית אונו" },
  { id: 412, name: "קרית שלמה" },
  { id: 2800, name: "קרית שמונה" },
  { id: 10001, name: "קרית שדה התעופה" }, //Added by me
  { id: 2300, name: "קרית טבעון" },
  { id: 9600, name: "קרית ים" },
  { id: 1137, name: "קרית יערים" },
  { id: 766, name: "קוממיות" },
  { id: 1179, name: "קורנית" },
  { id: 8700, name: "רעננה" },
  { id: 1161, name: "רהט" },
  { id: 1069, name: "רם-און" },
  { id: 135, name: "רמת דוד" },
  { id: 8600, name: "רמת גן" },
  { id: 184, name: "רמת הכובש" },
  { id: 2650, name: "רמת השרון" },
  { id: 335, name: "רמת השופט" },
  { id: 4701, name: "רמת מגשימים" },
  { id: 127, name: "רמת רחל" },
  { id: 460, name: "רמת רזיאל" },
  { id: 122, name: "רמת ישי" },
  { id: 178, name: "רמת יוחנן" },
  { id: 339, name: "רמת צבי" },
  { id: 543, name: "ראמה" },
  { id: 8500, name: "רמלה" },
  { id: 4702, name: "רמות" },
  { id: 206, name: "רמות השבים" },
  { id: 735, name: "רמות מאיר" },
  { id: 445, name: "רמות מנשה" },
  { id: 372, name: "רמות נפתלי" },
  { id: 789, name: "רנן" },
  { id: 1228, name: "רקפת" },
  { id: 1334, name: "ראס אל-עין" },
  { id: 990, name: "ראס עלי" },
  { id: 1225, name: "רביד" },
  { id: 713, name: "רעים" },
  { id: 444, name: "רגבים" },
  { id: 390, name: "רגבה" },
  { id: 3568, name: "ריחן" },
  { id: 3822, name: "רחלים" },
  { id: 854, name: "רחוב" },
  { id: 8400, name: "רחובות" },
  { id: 540, name: "ריחאניה" },
  { id: 542, name: "ריינה" },
  { id: 922, name: "רכסים" },
  { id: 437, name: "רשפים" },
  { id: 1260, name: "רתמים" },
  { id: 564, name: "רבדים" },
  { id: 3795, name: "רבבה" },
  { id: 354, name: "רביבים" },
  { id: 2051, name: "רווחה" },
  { id: 2016, name: "רוויה" },
  { id: 3565, name: "רימונים" },
  { id: 616, name: "רינתיה" },
  { id: 8300, name: "ראשון לציון" },
  { id: 247, name: "רשפון" },
  { id: 3619, name: "רועי" },
  { id: 2640, name: "ראש העין" },
  { id: 26, name: "ראש פינה" },
  { id: 3602, name: "ראש צורים" },
  { id: 3782, name: "רותם" },
  { id: 1341, name: "רוח מדבר" },
  { id: 362, name: "רוחמה" },
  { id: 997, name: "רומת הייב" },
  { id: 539, name: "רומאנה" },
  { id: 419, name: "סעד" },
  { id: 454, name: "סער" },
  { id: 1360, name: "סעוה" },
  { id: 7500, name: "סח'נין" },
  { id: 3567, name: "סלעית" },
  { id: 1245, name: "סלמה" },
  { id: 1156, name: "סמר" },
  { id: 636, name: "צנדלה" },
  { id: 1176, name: "ספיר" },
  { id: 126, name: "שריד" },
  { id: 578, name: "סאסא" },
  { id: 587, name: "סביון" },
  { id: 1223, name: "שדי אברהם" },
  { id: 885, name: "שדה בוקר" },
  { id: 36, name: "שדה דוד" },
  { id: 861, name: "שדה אליעזר" },
  { id: 304, name: "שדה אליהו" },
  { id: 2015, name: "שדי חמד" },
  { id: 721, name: "שדה אילן" },
  { id: 18, name: "שדה משה" },
  { id: 259, name: "שדה נחום" },
  { id: 329, name: "שדה נחמיה" },
  { id: 1058, name: "שדה ניצן" },
  { id: 2057, name: "שדי תרומות" },
  { id: 739, name: "שדה עוזיהו" },
  { id: 284, name: "שדה ורבורג" },
  { id: 142, name: "שדה יעקב" },
  { id: 2008, name: "שדה יצחק" },
  { id: 293, name: "שדה יואב" },
  { id: 2049, name: "שדה צבי" },
  { id: 1031, name: "שדרות" },
  { id: 27, name: "שדות מיכה" },
  { id: 327, name: "שדות ים" },
  { id: 1286, name: "שגב-שלום" },
  { id: 2046, name: "סגולה" },
  { id: 1132, name: "שניר" },
  { id: 538, name: "שעב" },
  { id: 4009, name: "שעל" },
  { id: 856, name: "שעלבים" },
  { id: 661, name: "שער אפרים" },
  { id: 237, name: "שער העמקים" },
  { id: 264, name: "שער הגולן" },
  { id: 921, name: "שער מנשה" },
  { id: 306, name: "שדמות דבורה" },
  { id: 3578, name: "שדמות מחולה" },
  { id: 692, name: "שפיר" },
  { id: 7, name: "שחר" },
  { id: 1266, name: "שחרות" },
  { id: 1373, name: "שלווה במדבר" },
  { id: 873, name: "שלווה" },
  { id: 432, name: "שמרת" },
  { id: 366, name: "שמיר" },
  { id: 1287, name: "שני" },
  { id: 3649, name: "שקד" },
  { id: 292, name: "שרונה" },
  { id: 398, name: "שרשרת" },
  { id: 1377, name: "שבי דרום" },
  { id: 3571, name: "שבי שומרון" },
  { id: 282, name: "שבי ציון" },
  { id: 324, name: "שאר ישוב" },
  { id: 555, name: "שדמה" },
  { id: 8800, name: "שפרעם" },
  { id: 232, name: "שפיים" },
  { id: 846, name: "שפר" },
  { id: 658, name: "שייח' דנון" },
  { id: 1160, name: "שכניה" },
  { id: 812, name: "שלומי" },
  { id: 439, name: "שלוחות" },
  { id: 1233, name: "שקף" },
  { id: 1045, name: "שתולה" },
  { id: 763, name: "שתולים" },
  { id: 1378, name: "שיזף" },
  { id: 527, name: "שזור" },
  { id: 865, name: "שיבולים" },
  { id: 913, name: "שבלי - אום אל-גנם" },
  { id: 1165, name: "שילת" },
  { id: 3641, name: "שילה" },
  { id: 3784, name: "שמעה" },
  { id: 1337, name: "שמשית" },
  { id: 1197, name: "נאות סמדר" },
  { id: 1364, name: "שלומית" },
  { id: 741, name: "שואבה" },
  { id: 1304, name: "שוהם" },
  { id: 614, name: "שומרה" },
  { id: 1265, name: "שומריה" },
  { id: 415, name: "שוקדה" },
  { id: 1235, name: "שורשים" },
  { id: 456, name: "שורש" },
  { id: 224, name: "שושנת העמקים" },
  { id: 1102, name: "צוקי ים" },
  { id: 394, name: "שובל" },
  { id: 761, name: "שובה" },
  { id: 610, name: "סתריה" },
  { id: 1238, name: "סופה" },
  { id: 526, name: "סולם" },
  { id: 3756, name: "סוסיה" },
  { id: 752, name: "תעוז" },
  { id: 462, name: "טל שחר" },
  { id: 1181, name: "טל-אל" },
  { id: 2050, name: 'תלמי ביל"ו' },
  { id: 2003, name: "תלמי אלעזר" },
  { id: 1051, name: "תלמי אליהו" },
  { id: 744, name: "תלמי יפה" },
  { id: 727, name: "תלמי יחיאל" },
  { id: 1237, name: "תלמי יוסף" },
  { id: 3788, name: "טלמון" },
  { id: 8900, name: "טמרה" },
  { id: 778, name: "תרום" },
  { id: 2730, name: "טייבה" },
  { id: 2062, name: "תאשור" },
  { id: 1214, name: "טפחות" },
  { id: 103, name: "תל עדשים" },
  { id: 5000, name: "תל אביב - יפו" },
  { id: 154, name: "תל מונד" },
  { id: 719, name: "תל קציר" },
  { id: 1054, name: "תל שבע" },
  { id: 1283, name: "תל תאומים" },
  { id: 287, name: "תל יצחק" },
  { id: 84, name: "תל יוסף" },
  { id: 1177, name: "טללים" },
  { id: 814, name: "תלמים" },
  { id: 3719, name: "תלם" },
  { id: 3743, name: "טנא" },
  { id: 2002, name: "תנובות" },
  { id: 3563, name: "תקוע" },
  { id: 665, name: "תקומה" },
  { id: 6700, name: "טבריה" },
  { id: 2061, name: "תדהר" },
  { id: 709, name: "תפרח" },
  { id: 163, name: "תימורים" },
  { id: 1244, name: "תמרת" },
  { id: 2100, name: "טירת כרמל" },
  { id: 663, name: "טירת יהודה" },
  { id: 268, name: "טירת צבי" },
  { id: 2720, name: "טירה" },
  { id: 10, name: "תירוש" },
  { id: 3558, name: "תומר" },
  { id: 4029, name: "רמת טראמפ" },
  { id: 962, name: "טובא-זנגריה" },
  { id: 498, name: "טורעאן" },
  { id: 1083, name: "תושיה" },
  { id: 1172, name: "תובל" },
  { id: 446, name: "אודים" },
  { id: 2710, name: "אום אל-פחם" },
  { id: 2024, name: "אום אל-קוטוף" },
  { id: 1358, name: "אום בטין" },
  { id: 957, name: "עוקבי" },
  { id: 403, name: "אורים" },
  { id: 278, name: "אושה" },
  { id: 826, name: "עוזה" },
  { id: 528, name: "עוזייר" },
  { id: 1133, name: "ורדון" },
  { id: 3639, name: "ורד יריחו" },
  { id: 1138, name: "יעד" },
  { id: 795, name: "יערה" },
  { id: 1117, name: "יעל" },
  { id: 577, name: "יד בנימין" },
  { id: 758, name: "יד חנה" },
  { id: 1134, name: "יד השמונה" },
  { id: 358, name: "יד מרדכי" },
  { id: 775, name: "יד נתן" },
  { id: 64, name: 'יד רמב"ם' },
  { id: 499, name: "יפיע" },
  { id: 3566, name: "יפית" },
  { id: 798, name: "יגל" },
  { id: 96, name: "יגור" },
  { id: 1158, name: "יהל" },
  { id: 811, name: "יכיני" },
  { id: 1295, name: "יאנוח-ג'ת" },
  { id: 753, name: "ינוב" },
  { id: 3647, name: "יקיר" },
  { id: 417, name: "יקום" },
  { id: 2026, name: "ירדנה" },
  { id: 718, name: "ירחיב" },
  { id: 183, name: "ירקונה" },
  { id: 575, name: "יסעור" },
  { id: 828, name: "ישרש" },
  { id: 1227, name: "יתד" },
  { id: 2660, name: "יבנה" },
  { id: 46, name: "יבנאל" },
  { id: 759, name: "יציץ" },
  { id: 1044, name: "יעף" },
  { id: 1144, name: "ידידה" },
  { id: 233, name: "כפר ידידיה" },
  { id: 409, name: "יחיעם" },
  { id: 9400, name: "יהוד-מונוסון" },
  { id: 831, name: "ירוחם" },
  { id: 916, name: "ישע" },
  { id: 440, name: "יסודות" },
  { id: 29, name: "יסוד המעלה" },
  { id: 1232, name: "יבול" },
  { id: 134, name: "יפעת" },
  { id: 453, name: "יפתח" },
  { id: 2011, name: "ינון" },
  { id: 623, name: "יראון" },
  { id: 502, name: "ירכא" },
  { id: 805, name: "ישעי" },
  { id: 3607, name: 'ייט"ב' },
  { id: 3749, name: "יצהר" },
  { id: 452, name: "יזרעאל" },
  { id: 1112, name: "יודפת" },
  { id: 4007, name: "יונתן" },
  { id: 240, name: "יקנעם עילית" },
  { id: 241, name: "יקנעם" },
  { id: 803, name: "יושיביה" },
  { id: 866, name: "יטבתה" },
  { id: 2009, name: "יובל" },
  { id: 1226, name: "יובלים" },
  { id: 1079, name: "צפרירים" },
  { id: 594, name: "צפריה" },
  { id: 816, name: "זנוח" },
  { id: 975, name: "זרזיר" },
  { id: 815, name: "זבדיאל" },
  { id: 413, name: "צאלים" },
  { id: 8000, name: "צפת" },
  { id: 799, name: "זכריה" },
  { id: 796, name: "צלפון" },
  { id: 1290, name: "זמר" },
  { id: 818, name: "זרחיה" },
  { id: 2064, name: "זרועה" },
  { id: 612, name: "צרופה" },
  { id: 788, name: "זיתן" },
  { id: 9300, name: "זכרון יעקב" },
  { id: 1065, name: "זמרת" },
  { id: 613, name: "ציפורי" },
  { id: 584, name: "זיקים" },
  { id: 1213, name: "צבעון" },
  { id: 1150, name: "צופר" },
  { id: 198, name: "צופית" },
  { id: 1111, name: "צופיה" },
  { id: 1136, name: "צוחר" },
  { id: 44, name: "זוהר" },
  { id: 567, name: "צרעה" },
  { id: 465, name: "צובה" },
  { id: 3791, name: "צופים" },
  { id: 1113, name: "צור הדסה" },
  { id: 276, name: "צור משה" },
  { id: 1148, name: "צור נתן" },
  { id: 1345, name: "צור יצחק" },
  { id: 774, name: "צוריאל" },
  { id: 1221, name: "צורית" },
  { id: 1180, name: "צביה" },
];

export const Cities = [
  { id: 28614, name: "טבריה" },
  { id: 28616, name: "באר שבע" },
  { id: 28620, name: "צפת" },
  { id: 28700, name: "זכרון יעקב" },
  { id: 28702, name: "ערד" },
  { id: 30545, name: "נווה שלום" },
  { id: 30549, name: "חניתה" },
  { id: 30552, name: "מטולה" },
  { id: 30553, name: "חברון" },
  { id: 30555, name: "הרדוף" },
  { id: 30567, name: "בית שאן" },
  { id: 30592, name: "כפר כנא" },
  { id: 30593, name: "בני ברק" },
  { id: 30595, name: "בית לחם הגלילית" },
  { id: 30600, name: "נגב" },
  { id: 30617, name: "הערבה" },
  { id: 30619, name: "הכינרת" },
  { id: 30621, name: "הגליל" },
  { id: 30622, name: "גוש דן" },
  { id: 38071, name: "אבו סנאן" },
  { id: 28556, name: "נצרת" },
  { id: 28701, name: "שדה בוקר" },
  { id: 28705, name: "מצפה רמון" },
  { id: 28707, name: "רמלה" },
  { id: 28712, name: "עכו" },
  { id: 28717, name: "עין גדי" },
  { id: 28738, name: "הרצליה" },
  { id: 28739, name: "נהריה" },
  { id: 28746, name: "גוש חלב" },
  { id: 30546, name: "כפר יאסיף" },
  { id: 30568, name: "בת שלמה" },
  { id: 30596, name: "בת-ים" },
  { id: 30598, name: "עמק עובדה והרי אילת" },
  { id: 30602, name: "מישור החוף" },
  { id: 30603, name: "מדבר יהודה" },
  { id: 30606, name: "חבל הבשור" },
  { id: 30608, name: "השפלה" },
  { id: 38083, name: "סאג'ור" },
  { id: 38086, name: "עין אל-אסד" },
  { id: 38090, name: "ריחאניה" },
  { id: 49366, name: "ים המלח" },
  { id: 28547, name: "רמת הגולן" },
  { id: 28555, name: "ירושלים" },
  { id: 28609, name: "תל אביב" },
  { id: 28714, name: "נתניה" },
  { id: 28745, name: "כפר נחום" },
  { id: 30547, name: "אשקלון" },
  { id: 30557, name: "אשדוד" },
  { id: 30566, name: "כפר כמא" },
  { id: 30569, name: "אבו גוש" },
  { id: 30597, name: "עמק יזרעאל" },
  { id: 30625, name: "אזור ניצנה" },
  { id: 38074, name: "ג'וליס" },
  { id: 38078, name: "ירכא" },
  { id: 38081, name: "כסרא" },
  { id: 38085, name: "חורפיש" },
  { id: 38087, name: "הכפר עספיא" },
  { id: 38088, name: "הכפר ראמה" },
  { id: 38089, name: "שפרעם" },
  { id: 38113, name: "דאלית אל-כרמל" },
  { id: 38651, name: "דגניה א'" },
  { id: 28548, name: "אילת" },
  { id: 28613, name: "חיפה" },
  { id: 28615, name: "קיסריה" },
  { id: 28703, name: "יד מרדכי" },
  { id: 28706, name: "תל חי" },
  { id: 28709, name: "ראש פינה" },
  { id: 28713, name: "קצרין" },
  { id: 28716, name: "קרית שמונה" },
  { id: 28733, name: "עין הוד" },
  { id: 28735, name: "פקיעין" },
  { id: 28740, name: "כפר תבור" },
  { id: 28741, name: "נהלל" },
  { id: 30551, name: "חצור הגלילית" },
  { id: 30554, name: "מעלות-תרשיחא" },
  { id: 30558, name: "המושבה כנרת" },
  { id: 30559, name: "חולון" },
  { id: 30599, name: "עמק החולה" },
  { id: 30616, name: "הרי ירושלים" },
  { id: 38073, name: "בית ג'אן" },
  { id: 38075, name: "ג'ת" },
  { id: 38077, name: "יאנוח" },
  { id: 38082, name: "מע'אר" },
  { id: 38084, name: "סמיע" },
];

export const DegreesList = [
  { name: "firstDegree", label: "תואר ראשון" },
  { name: "secondDegree", label: "תואר שני" },
  { name: "doctor", label: "דוקטור" },
  { name: "engineer", label: "מהנדס" },
  { name: "practicalEngineer", label: "הנדסאי" },
  { name: "technition", label: "טכנאי" },
];

export function translateDegreeAndType(degree) {
  switch (degree.trim()) {
    case "first":
    case "firstDegree":
      return "תואר ראשון";
    case "second":
    case "secondDegree":
      return "תואר שני";
    case "doctor":
      return "תואר דוקטור";
    case "engineer":
      return "תואר מהנדס";
    case "practicalEngineer":
      return "הנדסאי";
    case "technition":
      return "טכנאי";
    default:
      return "";
  }
}

export function reverseDegreeTranslation(value) {
  switch (value) {
    case "תואר ראשון":
      return "firstDegree";
    case "תואר שני":
      return "secondDegree";
    case "תואר דוקטור":
      return "doctor";
    case "תואר מהנדס":
      return "engineer";
    case "הנדסאי":
      return "practicalEngineer";
    case "טכנאי":
      return "technition";
    default:
      return "";
  }
}

export function translateLanguageLevel(level) {
  switch (level) {
    case "none":
      return "ללא שליטה בשפה";
    case "basic":
      return "בסיסית";
    case "average":
      return "ממוצעת";
    case "good":
      return "טובה";
    case "veryGood":
      return "טובה מאוד";
    case "motherTounge":
      return "שפת אם";
    default:
      return "ללא שליטה בשפה";
  }
}

export const tracerSources = [
  { label: "פייסבוק - מהיום למחר", id: 1 },
  { label: "פייסבוק - קבוצות כללי", id: 2 },
  { label: "אינסטגרם", id: 3 },
  { label: "EYWA LABS", id: 4 },
  { label: "אחר", id: 5 },
  { label: "פרסום בלינקדאין", id: 6 },
  { label: "חבר מביא חבר", id: 7 },
  { label: "B-Young Campaign", id: 8 },
  { label: "By – Ofek", id: 9 },
  { label: "Threads", id: 10 },
];

export const AddEventHandler = async (
  type,
  value,
  additionalInfo = "",
  isUpdateMode = false,
  candidateId,
  authorId = 1
) => {
  if (type === "sendInvitation" && value) {
    axios.post(`/wp-json/api/v2/publishjob/${value}`, {
      author: authorId,
      single_id: candidateId,
    });
  }
  try {
    const res = await axios.post(
      `/wp-json/api/v2/candidate_events/${candidateId}`,
      {
        eventKey: type,
        eventValue: value,
        eventAuthor: authorId,
        additionalInfo: additionalInfo,
        isUpdateMode,
      }
    );
    return res.data;
  } catch (err) {
    return err.response;
  }
};

export const MobilePrefixes = [
  { id: "050" },
  { id: "051" },
  { id: "052" },
  { id: "053" },
  { id: "054" },
  { id: "055" },
  { id: "056" },
  { id: "057" },
  { id: "058" },
  { id: "059" },
];

export const JobsCacheRefresh = 600000; //10 minutes by default;

export const getSalaryQuestion = (
  min,
  max,
  isOpenQuestion,
  openQuestionValueBy
) => {
  let str = "";
  if (isOpenQuestion) {
    str =
      openQuestionValueBy === "month"
        ? "מהן ציפיות השכר לחודש עבודה מלא <strong>(&#8362; לחודש)</strong>?"
        : "מהן ציפיות השכר לשעת עבודה <strong>(&#8362; לשעה)</strong>?";
  } else {
    if (min === max) {
      if (max < 250)
        str =
          "השכר המוצע למשרה זו הוא <strong>&#8362;" +
          min +
          "</strong> לשעה, האם זה מתאים לך?";
      else
        str =
          "השכר המוצע למשרה זו הוא <strong>&#8362;" +
          min +
          "</strong> האם זה מתאים לך?";
    } else {
      if ((max || min) < 250)
        str =
          "השכר המוצע למשרה זו הוא בין <strong>&#8362;" +
          min +
          "</strong> ל <strong>&#8362;" +
          max +
          "</strong> לשעה, האם זה מתאים לך?";
      else
        str =
          "השכר המוצע למשרה זו הוא בין <strong>&#8362;" +
          min +
          "</strong> ל <strong>&#8362;" +
          max +
          "</strong>, האם זה מתאים לך?";
    }
  }

  return str;
};
export function licensePreviewQuestion(license, additionalInfo) {
  return `כמה שנים יש לך רישיון ל<strong>${license}</strong>?<br>${additionalInfo}`;
}
export function experiencePreviewQuestion(
  profession,
  additionalInfo,
  pronounce_as = "ב"
) {
  return `כמה שנות ניסיון יש לך ${pronounce_as} <strong>${profession}</strong>?<br>${additionalInfo}`;
}
export function skillsPreviewQuestion(skill, additionalInfo) {
  return `מהי רמת הידע שלך ב <strong>${skill}</strong>?<br>${additionalInfo}`;
}
export function languagePreviewQuestion(language, additionalInfo) {
  return `מהי רמת השליטה שלך בשפה ה<strong>${language}</strong>?<br>${additionalInfo}`;
}
export function academicDegreePreviewQuestion(
  degree,
  type,
  isGeneral,
  additionalInfo
) {
  let preview = "";
  if (isGeneral)
    preview = `האם יש ברשותך <strong>${translateDegreeAndType(
      degree
    )}</strong>?`;
  else
    preview = `האם יש ברשותך <strong>${translateDegreeAndType(
      degree
    )}</strong> מסוג <strong>${type}</strong>?`;
  if (additionalInfo && additionalInfo !== "")
    preview += `<br/>${additionalInfo}`;
  return preview;
}

export function certificationPreviewQuestion(certification, additionalInfo) {
  let preview = "";
  preview = `האם יש ברשותך תעודת <strong>${certification}</strong>?<br>${
    additionalInfo && additionalInfo !== "" ? additionalInfo : ""
  }`;
  return preview;
}
