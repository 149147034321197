import React, { Fragment, useContext, useEffect, useState } from "react";
import Alert from "@material-ui/lab/Alert";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import PeriodSelection from "../../components/PeriodSelection/PeriodSelection";
import CachedRoundedIcon from "@material-ui/icons/CachedRounded";
import CloseRounded from "@material-ui/icons/CloseRounded";
import AgeFromToSelection from "../../components/AgeFromToSelection/AgeFromToSelection";
import GeneralAutocompleteSelect from "../../components/GeneralAutocompleteSelect/GeneralAutocomplete";
import GeneralSelection from "../../components/GeneralSelection/GeneralSelection";
import {
  Grid,
  Paper,
  Typography,
  Box,
  useTheme,
  Tooltip,
  Container,
  Divider,
  Button,
  Snackbar,
  IconButton,
  Collapse,
  TextField,
  Chip,
  InputAdornment,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import MyExploreSearches from "./MyExploreSearches";
import Spinner from "../../UI/Spinner/Spinner";
import DialogYesNo from "../../components/DialogYesNo/DialogYesNo";
import { ContinueIcon } from "../../Assets/CustomIcons/CustomIconsList";
import LibiExploreResults from "./LibiExploreResults";
import axios from "axios";
import EditExploreSearch from "./EditExploreSearch";
import AxiosErrorHandling from "../../components/AxiosErrorHandling/AxiosErrorHandling";
import AddIcon from "@material-ui/icons/Add";
import {
  AI_ACADEMIC_DGREE,
  AI_AVAILABILITY,
  AI_CERTIFICATES,
  AI_EXPERIENCES,
  AI_LANGUAGES,
  AI_LICENSES,
  AI_LOCATION,
  AI_SALARY_EXPECTATIONS,
  AI_SKILLS,
  DEGREE_TYPES,
} from "../../config/constants";
import { Autocomplete } from "@material-ui/lab";
import {
  FamilyStatusList,
  GenderList,
} from "../../config/candidatesConfigData";
import SelectRating from "../../components/SelectRating.js/SelectRating";
import {
  AreasList,
  CitiesList,
  DegreesList,
  LanguageLevels,
  SkillLevels,
} from "../../config/jobsConfigData";
import ConfigurationListsContext from "../../context/configurationListsContext";
import SelectExploreFQ, { FQFreeTextLevel } from "./SelectExploreFQ";
import StyledRating from "../../components/StyledRating/StyledRating";
//import SearchTagsAsync from "../../components/SearchTagsAsync/SearchTagsAsync";
import { filterOptions } from "../../config/constants";
import SalaryExpectationsSelection from "../../components/SalaryExpectationsSelection/SalaryExpectationsSelection";
import { DashboardPeriod } from "../../config/dashboardConfigData";
import useTags from "../../customHooks/useTags";
import FilterQuestionRelationsTag from "../Jobs/AddJob/FilterQuestions/FilterQuestionRelationsTag";
import AcceptedCitiesAsyncList from "../../components/AcceptedCitiesList/acceptedCitiesList";
import ErrorBoundary from "../../components/ErrorBoundary/ErrorBoundary";
//import AcademicDegree from "../Jobs/AddJob/FilterQuestions/AcademicDegree/AcademicDegree";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    "& .MuiPaper-root": {
      padding: theme.spacing(2),
      width: "100%",
    },
    "& .MuiFormControl-root": {
      width: "100%",
    },
  },
  readRow: {
    "& .MuiChip-root": {
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(1),
    },
  },
}));

const createSingleListForAllDegreeTypes = () => {
  let list = [];
  Object.keys(DEGREE_TYPES).forEach((degree) => {
    if (DEGREE_TYPES[degree].length > 0) {
      DEGREE_TYPES[degree].forEach((item) => {
        list = [
          ...list,
          {
            name: item,
            label: item,
          },
        ];
      });
    }
  });
  return list;
};

const Explore = () => {
  const classes = useStyles();
  const theme = useTheme();
  const [error, setError] = useState(null);
  const [candidatesFound, setCandidatesFound] = useState([]);
  const [searchData, setSearchData] = useState([]);
  const [addFilterRow, setAddFilterRow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(false);
  const [totalFound, setTotalFound] = useState(0);
  const [editSearch, setEditSearch] = useState(-1);
  const [savedSearches, setSavedSearches] = useState([]);
  const [searchUpdated, setSearchUpdated] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(-1); //if greater than 0 - show confirm dialog box to delete the id
  const [showEmptyResults, setShowEmptyResults] = useState(false);
  const [page, setPage] = useState(1);
  const { data: tagList } = useTags(1, 1000, "", []);
  React.useEffect(() => {
    getSearches();
  }, []);

  useEffect(() => {
    setPage(1);
  }, [searchData]);

  React.useEffect(() => {
    if (page !== 1) handleFindCandidates(buildFilterData());
  }, [page]);

  const getSearches = async () => {
    setInitialLoading(true);
    try {
      let response = await axios.get("/wp-json/api/v2/get_explore_searches");
      setInitialLoading(false);
      if (response.data) {
        setSavedSearches(response.data);
      }
    } catch (err) {
      setError(err.response);
      setInitialLoading(false);
    }
  };

  const handleRefreshList = () => {
    getSearches();
  };

  const handleCancelSearch = () => {
    setAddFilterRow(false);
    setSearchData([]);
  };

  const handleSaveSearch = (searchData) => {
    setSavedSearches(searchData);
  };

  const handleEditSearch = (searchId) => {
    const index = savedSearches.findIndex(
      (item) => parseInt(item.id) === parseInt(searchId)
    );
    if (index > -1) {
      setEditSearch(parseInt(searchId));
    }
  };

  const handleOnSelect = (searchId) => {
    //locate the search:
    const index = locateSearchIndex(parseInt(searchId));
    if (index > -1) {
      setSearchData(savedSearches[index].data);
      //findCandidates(savedSearches[index].data);
      //handleFindCandidates();
    }
  };

  const buildFilterData = () => {
    let filterObject = [];
    filterObject = searchData.map((filterItem) => ({
      type: filterItem.value.type,
      value: filterItem.value.value,
    }));
    return filterObject;
  };

  const handleFindCandidates = async () => {
    if (searchData.length === 0) return;

    const filterObj = buildFilterData();
    setIsLoading(true);
    const foundCands = await axios.post(
      "wp-json/api/v2/find_candidates_criteria",
      {
        filterData: filterObj,
        page,
      }
    );
    setTotalFound(parseInt(foundCands.headers["x-wp-total"]));
    if (parseInt(foundCands.headers["x-wp-total"]) === 0)
      setShowEmptyResults(true);
    setCandidatesFound(foundCands.data);
    setIsLoading(false);
  };

  const handleOnRemove = (searchId) => {
    const index = locateSearchIndex(parseInt(searchId));
    if (index > -1) {
      setConfirmDelete(parseInt(searchId));
    }
  };

  const handleRemoveSearch = async () => {
    const index = locateSearchIndex(confirmDelete);
    if (index > -1) {
      try {
        setIsLoading(true);
        setConfirmDelete(-1);
        //update the list - remove search from list by confirmDelete id
        let newSavedSearches = savedSearches;
        newSavedSearches.splice(index, 1);
        await axios.delete(
          `/wp-json/api/v2/remove_explore_search/${confirmDelete}`
        );
        setIsLoading(false);
        setSavedSearches(newSavedSearches);
      } catch (err) {
        setIsLoading(false);
        setConfirmDelete(-1);
        setError(err.response);
      }
    }
  };

  const locateSearchIndex = (searchId) => {
    return savedSearches.findIndex((item) => parseInt(item.id) === searchId);
  };

  const handleGetSearchData = () => {
    return savedSearches[locateSearchIndex(editSearch)];
  };

  //Data recieved from FilterRow component
  const handleAddFilterByType = (value) => {
    //const { tags } = value.value;
    let counterId = searchData.length + 1;
    setAddFilterRow(false);
    //special case: change city ids to cities names:
    if (value.type === "city") {
      let localValue = value;
      localValue = {
        ...localValue,
        value: localValue.value.map(
          (cityId) =>
            CitiesList.find((city) => city.id === parseInt(cityId)).name
        ),
      };
      setSearchData([...searchData, { id: counterId, value: localValue }]);
      return;
    }
    //special case for tags - we need to add multiple items to the array:
    if (value.type === "buildInTags") {
      // value.value.forEach((tagItem) => {
      //   setSearchData((prev) => [
      //     ...prev,
      //     {
      //       id: counterId,
      //       value: { ...tagItem, type: "buildInTags", isValid: true },
      //     },
      //   ]);
      // });
    } else if (value.type === "freeTags") {
      //special case: we need to see if previous free tag exists, if so - add to array.
      let newValue = value;
      const freeTagIndex = searchData.findIndex(
        (ft) => ft.value.type === "freeTags"
      );
      if (freeTagIndex > -1) {
        let freeTagsValue = searchData[freeTagIndex];
        freeTagsValue.value.value = [
          ...freeTagsValue.value.value,
          newValue.value,
        ];
        let localSearchData = searchData;
        localSearchData.splice(freeTagIndex, 1);
        localSearchData = [...localSearchData, freeTagsValue];
        setSearchData(localSearchData);
      } else {
        setSearchData([
          ...searchData,
          {
            id: counterId,
            value: { ...value, value: [newValue.value] },
          },
        ]);
      }
    } else {
      setSearchData((prev) => [...prev, { id: counterId, value: value }]);
    }
  };

  //the parameter is the id. need to find the item index
  const handleRemoveFilterItem = (value) => {
    const updateSearchData = [...searchData];
    const filterItemindex = updateSearchData.findIndex((fi) => fi.id === value);
    if (filterItemindex > -1) {
      updateSearchData.splice(filterItemindex, 1);
      setSearchData(updateSearchData);
    }
  };

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleOnSave = async (data) => {
    const index = locateSearchIndex(editSearch);
    const localSearches = savedSearches;
    localSearches[index] = data;
    setSavedSearches(localSearches);
    setEditSearch(-1);
    try {
      await axios.patch(`/wp-json/api/v2/edit_explore_search/${editSearch}`, {
        name: data.name,
      });
      setSearchUpdated(true);
    } catch (err) {
      setSearchUpdated(false);
      setError(err.response);
    }
  };

  const transformSearchDataItemToIncludeTags = (searchDataItem) => {
    if (searchDataItem.value.type === "buildInTags") return searchDataItem;
    //const { tags } = searchDataItem.value.value;
    let localSearchDataItem = searchDataItem;
    if (
      searchDataItem.value.value &&
      searchDataItem.value.value.tags &&
      tagList
    ) {
      let newTagsArray = [];
      const { tags } = localSearchDataItem.value.value;

      tags.forEach((t) => {
        const localRelatedTag = tagList.tags.find(
          (tl) => parseInt(tl.id) === t
        );
        if (localRelatedTag)
          newTagsArray = [
            ...newTagsArray,
            { id: parseInt(localRelatedTag.id), name: localRelatedTag.name },
          ];
      });
      if (newTagsArray.length)
        localSearchDataItem.value.value.tags = newTagsArray;
    }
    return localSearchDataItem;
  };

  return (
    <React.Fragment>
      <Container maxWidth="xl" className={classes.root}>
        {!candidatesFound ||
          (!candidatesFound.length && (
            <React.Fragment>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                style={{ padding: theme.spacing(2) }}
                spacing={3}
              >
                <Paper>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        my={theme.spacing(4)}
                      >
                        <Typography variant="h5">חיפוש לפי חתך</Typography>
                      </Box>
                    </Grid>

                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                    <Grid item xs={12}>
                      <Collapse in={addFilterRow}>
                        <FilterRow
                          onSelect={handleAddFilterByType}
                          onCancel={() => setAddFilterRow(false)}
                          disabledOption={searchData.map(
                            (option) => option.value.type
                          )}
                        />
                      </Collapse>
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        endIcon={<AddIcon />}
                        color="primary"
                        onClick={() => setAddFilterRow(true)}
                      >
                        הוסף סינון
                      </Button>
                    </Grid>
                    {searchData.length > 0 && (
                      <Fragment>
                        <Grid item xs={2}>
                          <Typography variant="body1" color="textSecondary">
                            סוג הסינון
                          </Typography>
                        </Grid>
                        <Grid item xs={8}>
                          <Typography variant="body1" color="textSecondary">
                            פרטי הסינון
                          </Typography>
                        </Grid>
                        <Grid item xs={2}>
                          פעולות
                        </Grid>
                      </Fragment>
                    )}
                    {searchData.length > 0 &&
                      searchData.map((searchItem) => (
                        <ReadOnlyFilterRowByType
                          filterRow={transformSearchDataItemToIncludeTags(
                            searchItem
                          )}
                          key={searchItem.id}
                          onRemove={handleRemoveFilterItem}
                        />
                      ))}
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>

                    <Grid item xs={12}>
                      <Button
                        variant="contained"
                        color="primary"
                        endIcon={<ContinueIcon />}
                        style={{ float: "left" }}
                        disabled={
                          isLoading ||
                          searchData.length === 0 ||
                          !tagList ||
                          tagList.length === 0
                        }
                        onClick={handleFindCandidates}
                      >
                        מצא מועמדים
                      </Button>
                      <Button
                        style={{ float: "left", marginLeft: theme.spacing(2) }}
                        onClick={handleCancelSearch}
                        disabled={isLoading}
                      >
                        בטל
                      </Button>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </React.Fragment>
          ))}
      </Container>
      {candidatesFound && candidatesFound.length > 0 && (
        <LibiExploreResults
          candidates={candidatesFound}
          total={totalFound}
          pages={Math.ceil(totalFound / 20)}
          searchData={searchData}
          onSaveSearch={handleSaveSearch}
          onBack={() => setCandidatesFound([])}
          onPageChange={handlePageChange}
          currentPage={page}
        />
      )}
      <Snackbar
        open={showEmptyResults && candidatesFound.length === 0}
        autoHideDuration={6000}
        onClose={() => setShowEmptyResults(false)}
      >
        <Alert onClose={() => setShowEmptyResults(false)} severity="info">
          לא נמצאו תוצאות לחיפוש זה
        </Alert>
      </Snackbar>

      {initialLoading && <Spinner open={initialLoading} />}
      {(!candidatesFound || !candidatesFound.length) && (
        <Container maxWidth={"xl"}>
          <Box
            display="flex"
            justifyContent="flex-start"
            alignItems="center"
            my={theme.spacing(4)}
          >
            <Typography variant="h4">החיפושים שלי</Typography>
            <Tooltip title="רענון">
              <IconButton
                aria-label="back"
                onClick={handleRefreshList}
                color="primary"
                style={{ marginRight: theme.spacing(1) }}
              >
                <CachedRoundedIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Box>
          <MyExploreSearches
            results={savedSearches}
            onEdit={handleEditSearch}
            onSelect={handleOnSelect}
            onRemove={handleOnRemove}
            onPageChange={handlePageChange}
          />
          {searchUpdated && (
            <Snackbar
              open={searchUpdated}
              autoHideDuration={3000}
              onClose={() => setSearchUpdated(false)}
            >
              <Alert severity="success">החיפוש נשמר בהצלחה</Alert>
            </Snackbar>
          )}
        </Container>
      )}
      {editSearch > 0 && (
        <EditExploreSearch
          open={editSearch > 0}
          close={() => setEditSearch(-1)}
          searchData={handleGetSearchData()}
          onSave={handleOnSave}
        />
      )}
      {error && (
        <AxiosErrorHandling
          title={"אירעה שגיאה בעת ניסיון איתור מועמדים"}
          error={error}
        />
      )}
      {isLoading && <Spinner open={isLoading} />}
      {confirmDelete > -1 && (
        <DialogYesNo
          open={confirmDelete > -1}
          onClose={() => setConfirmDelete(-1)}
          title="הסרת חיפוש"
          description={`האם להסיר את חיפוש מספר ${confirmDelete}?`}
          positiveButtonLabel={"כן"}
          onConfirm={handleRemoveSearch}
          negativeButtonLabel="לא"
        />
      )}
    </React.Fragment>
  );
};
export default Explore;

const FilterRow = ({ onSelect, onCancel, disabledOption }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [filterType, setFilterType] = useState("");
  const [filterItem, setFilterItem] = useState({});
  const [selectedPeriod, setSelectedPeriod] = React.useState("allTimes");
  const [customDateFrom, setCustomDateFrom] = React.useState(
    moment().subtract(1, "days").format("YYYY/MM/DD")
  );
  const [customDateTo, setCustomDateTo] = React.useState(
    moment().format("YYYY/MM/DD")
  );
  const context = useContext(ConfigurationListsContext);

  useEffect(() => {
    setFilterItem({
      value: {
        from: customDateFrom,
        to: customDateTo,
        periodType: selectedPeriod,
      },
      type: filterType,
      isValid: selectedPeriod !== "allTimes",
    });
  }, [customDateFrom, customDateTo, selectedPeriod]);

  const handleConfirmFilterItem = () => {
    const localData = filterItem;
    setFilterType("");
    setFilterItem({});
    setSelectedPeriod("allTimes");
    onSelect(localData);
  };

  const handleOnCancel = () => {
    setFilterType("");
    setFilterItem({});
    onCancel();
  };

  const handleTagKeyPress = (event) => {
    let localTag = "";

    if (/^[A-Za-z0-9\u0590-\u05fe_@]+$/.test(event.target.value)) {
      //setTag(event.target.value);
      localTag = event.target.value;
    } else {
      //remove last char
      localTag = event.target.value.substring(0, event.target.value.length - 1);
    }
    setFilterItem({
      value: localTag,
      type: filterType,
      isValid: event.target.value !== "",
    });
  };

  const handlePeriodChange = (event) => {
    setSelectedPeriod(event.target.value);
    let localPeriod = {};
    switch (event.target.value) {
      case "today":
        localPeriod = {
          from: moment().format("YYYY/MM/DD"),
          to: moment().format("YYYY/MM/DD"),
        };

        break;
      case "yesterday":
        localPeriod = {
          from: moment().subtract(1, "days").format("YYYY/MM/DD"),
          to: moment().format("YYYY/MM/DD"),
        };

        break;
      case "last7":
        localPeriod = {
          from: moment().subtract(7, "days").format("YYYY/MM/DD"),
          to: moment().format("YYYY/MM/DD"),
        };
        break;
      case "last28":
        localPeriod = {
          from: moment().subtract(28, "days").format("YYYY/MM/DD"),
          to: moment().format("YYYY/MM/DD"),
        };
        break;
      case "last90":
        localPeriod = {
          from: moment().subtract(90, "days").format("YYYY/MM/DD"),
          to: moment().format("YYYY/MM/DD"),
        };
        break;
      case "thisYear":
        localPeriod = {
          from: moment(`${moment().year()}/1/1`, "YYYY/MM/DD").format(
            "YYYY/MM/DD"
          ),
          to: moment(`${moment().year()}/12/31`, "YYYY/MM/DD").format(
            "YYYY/MM/DD"
          ),
        };
        break;
      case "allTimes": {
        localPeriod = {
          from: moment(1900 / 1 / 1, "YYYY/MM/DD").format("YYYY/MM/DD"),
          to: moment(2099 / 1 / 1, "YYYY/MM/DD").format("YYYY/MM/DD"),
        };
        break;
      }
      default:
        localPeriod = {
          from: moment().subtract(7, "days").format("YYYY/MM/DD"),
          to: moment().format("YYYY/MM/DD"),
        };
        break;
    }
    setCustomDateFrom(localPeriod.from);
    setCustomDateTo(localPeriod.to);
  };

  const displayRowByType = () => {
    switch (filterType) {
      case "ages": {
        return (
          <Box width={"50%"}>
            <AgeFromToSelection
              onSelect={(value) =>
                setFilterItem({
                  value,
                  type: filterType,
                  isValid: value.ageFrom <= value.ageTo,
                })
              }
            />
          </Box>
        );
      }
      case "gender": {
        return (
          <Box width={"50%"}>
            <GeneralSelection
              listOptions={Object.keys(GenderList).map((item) => {
                return {
                  name: item,
                  label: GenderList[item],
                };
              })}
              label="בחירת מגדר"
              onSelect={(value) =>
                setFilterItem({
                  value,
                  type: filterType,
                  isValid: value !== "none",
                })
              }
            />
          </Box>
        );
      }
      case "familyStatus": {
        return (
          <Box width={"50%"}>
            <GeneralAutocompleteSelect
              list={Object.keys(FamilyStatusList).map((item) => {
                return {
                  name: item,
                  label: FamilyStatusList[item],
                };
              })}
              label="מצב משפחתי"
              placeholder="הוסף מצב משפחתי..."
              onSelect={(value) =>
                setFilterItem({
                  value,
                  type: filterType,
                  isValid: true,
                })
              }
            />
          </Box>
        );
      }
      case "rating": {
        return (
          <SelectRating
            onSelect={(value) =>
              setFilterItem({
                value,
                type: filterType,
                isValid: value > 0,
              })
            }
          />
        );
      }
      case "city": {
        return (
          <Box minWidth={"50%"}>
            <GeneralAutocompleteSelect
              onSelect={(value) =>
                setFilterItem({
                  value,
                  type: filterType,
                  isValid: value && value.length > 0,
                })
              }
              list={CitiesList.map((city) => {
                return {
                  name: city.id.toString(),
                  label: city.name,
                };
              })}
              label="בחר יישובים"
              placeholder={"יישוב לסינון..."}
            />
          </Box>
        );
      }
      case "workingArea": {
        return (
          <Box width={"50%"}>
            <GeneralAutocompleteSelect
              list={Object.keys(AreasList).map((item) => {
                return {
                  name: item,
                  label: AreasList[item],
                };
              })}
              label="בחירת אזור עבודה"
              placeholder={"הוספת אזור עבודה..."}
              onSelect={(value) =>
                setFilterItem({
                  value,
                  type: filterType,
                  isValid: value && value.length,
                })
              }
            />
          </Box>
        );
      }
      case "professions": {
        return (
          <Box minWidth={"50%"}>
            <GeneralAutocompleteSelect
              onSelect={(value) =>
                setFilterItem({
                  value,
                  type: filterType,
                  isValid: value && value.length,
                })
              }
              list={context.professionsList.map((prof) => {
                return {
                  name: prof.id.toString(),
                  label: prof.name,
                };
              })}
              label="בחירת מקצועות"
              placeholder={"מקצוע..."}
            />
          </Box>
        );
      }
      case AI_EXPERIENCES: {
        return (
          <Box width={"100%"}>
            <SelectExploreFQ
              fqLabel={"בחירת מקצוע"}
              fqValueLabel="שנות ניסיון מינימלי"
              onSelect={(value) => {
                setFilterItem({
                  value,
                  type: filterType,
                  isValid:
                    (value.type !== "" || value.tags.length > 0) &&
                    value.value > 0,
                });
              }}
            />
          </Box>
        );
      }
      case AI_SKILLS: {
        return (
          <Box width={"100%"}>
            <FQFreeTextLevel
              fqLabel={"כישור לסינון"}
              fqValueLabel="מינימום רמת ידע"
              onSelect={(value) => {
                setFilterItem({
                  value,
                  type: filterType,
                  isValid:
                    (value.type !== "" || value.tags.length > 0) &&
                    value.value !== "",
                });
              }}
              levelList={SkillLevels.map((level) => {
                return {
                  name: level.value,
                  label: level.label,
                };
              })}
              category={AI_SKILLS}
            />
          </Box>
        );
      }
      case AI_LICENSES: {
        return (
          <Box
            display={"flex"}
            alignItems="center"
            justifyContent={"space-between"}
            style={{ width: "100%" }}
          >
            <TextField
              variant="filled"
              margin="normal"
              label="סוג הרישון"
              onChange={(event) =>
                setFilterItem((prev) => ({
                  value: {
                    type: event.target.value,
                    tags: prev.tags ? prev.tags : [],
                  },
                  type: filterType,
                  isValid:
                    event.target.value !== "" ||
                    (prev.value.tags && prev.value.tags.length > 0),
                }))
              }
              style={{ flex: 1 }}
            />
            <FilterQuestionRelationsTag
              category={AI_LICENSES}
              onSelect={(value) => {
                setFilterItem((prev) => ({
                  type: AI_LICENSES,
                  value: {
                    type: prev.value && prev.value.type ? prev.value.type : "",
                    tags: value,
                  },
                  isValid: value.length > 0 || prev.value.type !== "",
                }));
              }}
              styling={{
                marginRight: theme.spacing(2),
                flex: 1,
              }}
            />
          </Box>
        );
      }
      case AI_ACADEMIC_DGREE: {
        return (
          <Box
            display={"flex"}
            alignItems="center"
            justifyContent={"space-between"}
            style={{ width: "100%" }}
          >
            <GeneralAutocompleteSelect
              list={DegreesList}
              label="בחירת תואר לסינון"
              placeholder={"בחר תואר..."}
              styling={{ flex: 1 }}
              onSelect={(value) =>
                setFilterItem({
                  value: {
                    degree: value,
                    degreeType:
                      filterItem.value && filterItem.value.degreeType
                        ? filterItem.value.degreeType
                        : null,
                  },
                  type: filterType,
                  isValid: value && value.length,
                })
              }
            />
            <GeneralAutocompleteSelect
              list={createSingleListForAllDegreeTypes()}
              label="בחירת סוג התואר"
              placeholder={"בחר סוג תואר..."}
              styling={{
                marginRight: theme.spacing(2),
                flex: 1,
              }}
              onSelect={(value) =>
                setFilterItem({
                  value: {
                    degree:
                      filterItem.value && filterItem.value.degree
                        ? filterItem.value.degree
                        : null,
                    degreeType: value,
                  },
                  type: filterType,
                  isValid: value && value.length,
                })
              }
            />
            <FilterQuestionRelationsTag
              category={AI_ACADEMIC_DGREE}
              onSelect={(value) => {
                setFilterItem((prev) => ({
                  type: AI_ACADEMIC_DGREE,
                  value: {
                    type: prev.value && prev.value.type ? prev.value.type : "",
                    tags: value,
                  },
                  isValid: value.length > 0 || prev.value.type !== "",
                }));
              }}
              styling={{
                marginRight: theme.spacing(2),
                flex: 1,
              }}
            />
          </Box>
        );
      }
      case AI_CERTIFICATES: {
        return (
          <Box
            display={"flex"}
            alignItems="center"
            justifyContent={"space-between"}
            style={{ width: "100%" }}
          >
            <TextField
              variant="filled"
              margin="normal"
              label="סוג התעודה"
              onChange={(event) =>
                setFilterItem((prev) => ({
                  value: {
                    type: event.target.value,
                    tags: prev.tags ? prev.tags : [],
                  },
                  type: filterType,
                  isValid:
                    event.target.value !== "" ||
                    (prev.value.tags && prev.value.tags.length > 0),
                }))
              }
              style={{ flex: 1 }}
            />
            <FilterQuestionRelationsTag
              category={AI_CERTIFICATES}
              onSelect={(value) => {
                setFilterItem((prev) => ({
                  type: AI_CERTIFICATES,
                  value: {
                    type: prev.value && prev.value.type ? prev.value.type : "",
                    tags: value,
                  },
                  isValid: value.length || prev.value.type !== "",
                }));
              }}
              styling={{
                marginRight: theme.spacing(2),
                flex: 1,
              }}
            />
          </Box>
        );
      }
      case AI_LANGUAGES: {
        return (
          <Box width={"100%"}>
            <FQFreeTextLevel
              fqLabel={"שפה לסינון"}
              fqValueLabel="מינימום רמת ידע"
              category={AI_LANGUAGES}
              onSelect={(value) =>
                setFilterItem({
                  value,
                  type: filterType,
                  isValid:
                    (value.type !== "" || value.tags.length > 0) &&
                    value.value !== "",
                })
              }
              levelList={LanguageLevels.map((level) => {
                return {
                  name: level.value,
                  label: level.label,
                };
              })}
            />
          </Box>
        );
      }
      case AI_AVAILABILITY: {
        return (
          <Box width={"50%"}>
            <FilterQuestionRelationsTag
              category={AI_AVAILABILITY}
              onSelect={(value) => {
                setFilterItem((prev) => ({
                  type: AI_AVAILABILITY,
                  value: {
                    type: prev.value && prev.value.type ? prev.value.type : "",
                    tags: value,
                  },
                  isValid: value.length > 0 || prev.value.type !== "",
                }));
              }}
              styling={{
                marginRight: theme.spacing(2),
                flex: 1,
              }}
            />
          </Box>
        );
      }
      case AI_LOCATION: {
        return (
          <Box width={"50%"}>
            <FilterQuestionRelationsTag
              category={AI_LOCATION}
              onSelect={(value) => {
                setFilterItem((prev) => ({
                  type: AI_LOCATION,
                  value: {
                    type: prev.value && prev.value.type ? prev.value.type : "",
                    tags: value,
                  },
                  isValid: value.length > 0 || prev.value.type !== "",
                }));
              }}
              styling={{
                marginRight: theme.spacing(2),
                flex: 1,
              }}
            />
          </Box>
        );
      }
      case "acceptedCities": {
        return (
          <Box minWidth={"50%"}>
            <AcceptedCitiesAsyncList
              onSelect={(value) =>
                setFilterItem({
                  value,
                  type: filterType,
                  isValid: value && value.length > 0,
                })
              }
            />
          </Box>
        );
      }
      case "freeTags": {
        return (
          <Box width={"50%"}>
            <TextField
              label="שם התגית"
              id="labelName"
              placeholder="הוספת תגית"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">#</InputAdornment>
                ),
              }}
              InputLabelProps={{
                shrink: true,
              }}
              variant="filled"
              onKeyUp={handleTagKeyPress}
              margin="normal"
              onChange={handleTagKeyPress}
              value={filterItem.type === "freeTags" ? filterItem.value : ""}
            />
          </Box>
        );
      }
      case AI_SALARY_EXPECTATIONS: {
        return (
          <Box width={"100%"}>
            <SalaryExpectationsSelection
              onSelect={(value) =>
                setFilterItem({
                  value,
                  type: filterType,
                  isValid:
                    (value.value.salaryTo > 0 &&
                      value.value.salaryTo > value.value.salaryFrom) ||
                    value.tags.length > 0,
                })
              }
            />
          </Box>
        );
      }
      case "addedToSystem": {
        return (
          <Fragment>
            <Box width={"25%"}>
              <PeriodSelection
                fullWidth
                label="תקופת זמן"
                filled
                selectedPeriod={selectedPeriod}
                onPeriodChange={handlePeriodChange}
              />
            </Box>
            <Collapse in={selectedPeriod === "custom"}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-beween"
              >
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    id="date-picker-from"
                    label="מתאריך"
                    maxDate={customDateTo}
                    value={customDateFrom}
                    onChange={(date) =>
                      setCustomDateFrom(moment(date).format("YYYY/MM/DD"))
                    }
                    KeyboardButtonProps={{
                      "aria-label": "fromDate",
                    }}
                    style={{
                      marginTop: 8,
                      marginRight: theme.spacing(2),
                    }}
                  />
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    id="date-picker-to"
                    label="עד תאריך"
                    minDate={customDateFrom}
                    value={customDateTo}
                    onChange={(date) =>
                      setCustomDateTo(moment(date).format("YYYY/MM/DD"))
                    }
                    KeyboardButtonProps={{
                      "aria-label": "toDate",
                    }}
                    style={{
                      marginTop: 8,
                      marginRight: theme.spacing(2),
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Box>
            </Collapse>
          </Fragment>
        );
      }
      default:
        return;
    }
  };
  return (
    <Grid container spacing={2} className={classes.root}>
      <Grid item xs={2}>
        <Autocomplete
          options={filterOptions.sort((a, b) => (a.label > b.label ? 1 : -1))}
          getOptionDisabled={(option) =>
            disabledOption.includes(option.name) &&
            option.allowDuplicate === false
          }
          getOptionLabel={(type) => (type.label ? type.label : "")}
          id="filter"
          onChange={(e, value) => setFilterType(value ? value.name : "")}
          value={
            filterType !== ""
              ? filterOptions.find((item) => item.name === filterType)
              : null
          }
          renderInput={(params) => (
            <TextField
              {...params}
              margin="normal"
              label="בחר סוג סינון"
              variant="filled"
            />
          )}
        />
      </Grid>
      <Grid item xs={8}>
        <Box
          display={"flex"}
          alignItems="center"
          justifyContent={"flex-between"}
          height="100%"
        >
          {displayRowByType()}
        </Box>
      </Grid>
      <Grid item xs={2}>
        <Box
          display={"flex"}
          justifyContent="space-between"
          alignItems={"center"}
          height={"100%"}
        >
          <Button
            endIcon={<AddIcon />}
            disabled={filterItem.isValid ? !filterItem.isValid : true}
            onClick={handleConfirmFilterItem}
          >
            אישור
          </Button>
          <IconButton onClick={handleOnCancel}>
            <CloseRounded style={{ color: "red" }} />
          </IconButton>
        </Box>
      </Grid>
    </Grid>
  );
};

export const ReadOnlyFilterRowByType = ({
  filterRow,
  onRemove,
  withActions = true,
}) => {
  const classes = useStyles();
  const context = useContext(ConfigurationListsContext);

  const printTagsFilterRow = (filterRow) => {
    const tagsLen = filterRow.value.value.tags
      ? filterRow.value.value.tags.length
      : 0;
    return (
      filterRow.value.value.tags &&
      filterRow.value.value.tags.map((t, i) => {
        return (
          <Box
            display={"flex"}
            alignItems="center"
            justifyContent={"flex-start"}
            key={t.id}
          >
            <Chip label={`#${t.name}`} />{" "}
            {tagsLen > i + 1 && (
              <Typography mx={1} variant="body1">
                או
              </Typography>
            )}
          </Box>
        );
      })
    );
  };
  const printRow = () => {
    switch (filterRow.value.type) {
      case "gender":
        return (
          <Box width={"50%"}>
            <Typography variant="body1" color="textPrimary">
              {GenderList[filterRow.value.value]}
            </Typography>
          </Box>
        );
      case "familyStatus":
        return (
          <Box
            width={"50%"}
            display={"flex"}
            justifyContent="flex-start"
            alignItems={"center"}
          >
            {filterRow.value.value.map((fs, inx) => {
              return inx < filterRow.value.value.length - 1 ? (
                <Box
                  display={"flex"}
                  justifyContent="flex-start"
                  alignItems={"center"}
                  key={fs}
                >
                  <Chip label={FamilyStatusList[fs]} />
                  <Typography variant="body1"> או </Typography>
                </Box>
              ) : (
                <Chip label={FamilyStatusList[fs]} key={fs} />
              );
            })}
          </Box>
        );
      case "ages":
        return (
          <Box width={"50%"}>
            <Typography variant="body1" color="textPrimary">
              {`מגיל ${filterRow.value.value.ageFrom} עד גיל ${filterRow.value.value.ageTo}`}
            </Typography>
          </Box>
        );
      case "rating":
        return (
          <Box width={"50%"}>
            <StyledRating
              value={filterRow.value.value}
              name={"CandidateRating"}
              precision={0.5}
            />
          </Box>
        );
      case "city":
        return (
          <Box
            width={"50%"}
            display="flex"
            justifyContent={"flex-start"}
            alignItems="center"
          >
            {filterRow.value.value.map((city, inx) => {
              return inx < filterRow.value.value.length - 1 ? (
                <Box
                  display={"flex"}
                  justifyContent="flex-start"
                  alignItems={"center"}
                  key={city}
                >
                  <Chip
                    label={CitiesList.find((ci) => ci.name === city).name}
                  />
                  <Typography variant="body1"> או </Typography>
                </Box>
              ) : (
                <Chip
                  label={CitiesList.find((ci) => ci.name === city).name}
                  key={city}
                />
              );
            })}
          </Box>
        );
      case "workingArea":
        return (
          <Box
            width={"50%"}
            display="flex"
            justifyContent={"flex-start"}
            alignItems="center"
          >
            {filterRow.value.value.map((area, inx) => {
              return inx < filterRow.value.value.length - 1 ? (
                <Box
                  display={"flex"}
                  justifyContent="flex-start"
                  alignItems={"center"}
                  key={area}
                >
                  <Chip
                    label={
                      context.areasList.find(
                        (ai) => parseInt(ai.id) === parseInt(area)
                      ).value
                    }
                  />
                  <Typography variant="body1"> או </Typography>
                </Box>
              ) : (
                <Chip
                  label={
                    context.areasList.find(
                      (ai) => parseInt(ai.id) === parseInt(area)
                    ).value
                  }
                  key={area}
                />
              );
            })}
          </Box>
        );
      case "professions":
        return (
          <Box
            width={"50%"}
            display="flex"
            justifyContent={"flex-start"}
            alignItems="center"
          >
            {filterRow.value.value.map((prof, inx) => {
              return inx < filterRow.value.value.length - 1 ? (
                <Box
                  display="flex"
                  justifyContent={"flex-start"}
                  alignItems="center"
                  key={prof}
                >
                  <Chip
                    label={
                      context.professionsList.find(
                        (ai) => parseInt(ai.id) === parseInt(prof)
                      ).name
                    }
                  />
                  <Typography variant="body1"> או </Typography>
                </Box>
              ) : (
                <Chip
                  label={
                    context.professionsList.find(
                      (ai) => parseInt(ai.id) === parseInt(prof)
                    ).name
                  }
                  key={prof}
                />
              );
            })}
          </Box>
        );

      case "addedToSystem": {
        return (
          <Box width={"100%"} display="flex" alignItems={"center"}>
            <Typography
              variant="body1"
              color={"textPrimary"}
            >{`התווסף למערכת בין התאריכים ${new Date(
              filterRow.value.value.from
            ).toLocaleDateString()} ל ${new Date(
              filterRow.value.value.to
            ).toLocaleDateString()} (${
              DashboardPeriod.find(
                (item) => item.value === filterRow.value.value.periodType
              ).label
            })`}</Typography>
          </Box>
        );
      }
      case "freeTags":
        return (
          <Box width={"100%"} display="flex" alignItems={"center"}>
            <Box width={"50%"}>
              {filterRow.value.value.map((tag) => (
                <Chip label={`#${tag}`} />
              ))}
            </Box>
          </Box>
        );
      case AI_EXPERIENCES:
        return (
          <Box
            width={"100%"}
            display="flex"
            alignItems={"center"}
            justifyContent={"flex-start"}
          >
            {filterRow.value.value.type && (
              <Box style={{ flex: 1 }}>
                <Typography variant="body1" color="textPrimary">
                  {filterRow.value.value.type}
                </Typography>
              </Box>
            )}
            <Box style={{ flex: 1 }}>
              <Typography
                variant="body1"
                color="textPrimary"
              >{`גדול או שווה ל ${filterRow.value.value.value} שנות ניסיון`}</Typography>
            </Box>
            <Box
              style={{ flex: 1 }}
              display="flex"
              justifyContent={"flex-start"}
              alignItems="center"
            >
              {printTagsFilterRow(filterRow)}
            </Box>
          </Box>
        );
      case AI_SKILLS:
        return (
          <Box
            width={"100%"}
            display="flex"
            justifyContent={"flex-start"}
            alignItems="center"
          >
            {filterRow.value.value.type && (
              <Box style={{ flex: 1 }}>
                <Typography variant="body1" color="textPrimary">
                  {filterRow.value.value.type}
                </Typography>
              </Box>
            )}

            <Box
              display={"flex"}
              justifyContent="flex-start"
              alignItems={"center"}
              style={{ flex: 1 }}
            >
              <Typography
                variant="body1"
                color="textPrimary"
              >{`מינימום רמת ידע: ${
                SkillLevels.find(
                  (sl) => sl.value === filterRow.value.value.value
                ).label
              }`}</Typography>
            </Box>
            <Box
              display={"flex"}
              justifyContent="flex-start"
              alignItems={"center"}
              style={{ flex: 1 }}
            >
              {printTagsFilterRow(filterRow)}
            </Box>
          </Box>
        );
      case AI_LICENSES:
        return (
          <Box
            width={"100%"}
            display="flex"
            justifyContent={"flex-start"}
            alignItems="center"
          >
            {filterRow.value.value.type && (
              <Box
                display={"flex"}
                justifyContent="flex-start"
                alignItems={"center"}
                style={{ flex: 1 }}
              >
                <Typography variant="body1" color="textPrimary">
                  {filterRow.value.value.type}
                </Typography>
              </Box>
            )}

            <Box
              display={"flex"}
              justifyContent="flex-start"
              alignItems={"center"}
              style={{ flex: 1 }}
            >
              {printTagsFilterRow(filterRow)}
            </Box>
          </Box>
        );
      case AI_ACADEMIC_DGREE:
        return (
          <Box
            width={"100%"}
            display="flex"
            justifyContent={"flex-start"}
            alignItems="center"
          >
            {filterRow.value.value.degree && (
              <Box
                style={{ flex: 1 }}
                display="flex"
                justifyContent={"flex-start"}
                alignItems={"center"}
              >
                <Typography variant="body1" color="textPrimary">
                  תואר:
                </Typography>
                {filterRow.value.value.degree.map((degree, inx) => (
                  <Box
                    display="flex"
                    justifyContent={"flex-start"}
                    alignItems="center"
                    key={degree}
                  >
                    <Chip
                      label={DegreesList.find((d) => d.name === degree).label}
                    />
                    {inx < filterRow.value.value.degree.length - 1 && (
                      <Typography variant="body1" color="textPrimary">
                        {" "}
                        או{" "}
                      </Typography>
                    )}
                  </Box>
                ))}
              </Box>
            )}
            {filterRow.value.value.degreeType && (
              <Box
                display="flex"
                justifyContent={"flex-start"}
                alignItems={"center"}
                style={{ flex: 1 }}
              >
                <Typography variant="body1"> מסוג</Typography>

                {filterRow.value.value.degreeType.map((degreeType, inx) => (
                  <Box
                    display="flex"
                    justifyContent={"flex-start"}
                    alignItems="center"
                    key={degreeType}
                  >
                    <Chip label={degreeType} />
                    {inx < filterRow.value.value.degreeType.length - 1 && (
                      <Typography variant="body1" color="textPrimary">
                        {" "}
                        או{" "}
                      </Typography>
                    )}
                  </Box>
                ))}
              </Box>
            )}
            <Box
              display={"flex"}
              justifyContent="flex-start"
              alignItems={"center"}
              style={{ flex: 1 }}
            >
              {printTagsFilterRow(filterRow)}
            </Box>
          </Box>
        );
      case AI_CERTIFICATES:
        return (
          <Box
            width={"100%"}
            display="flex"
            justifyContent={"flex-start"}
            alignItems="center"
          >
            {filterRow.value.value.type && (
              <Box
                display={"flex"}
                justifyContent="flex-start"
                alignItems={"center"}
                style={{ flex: 1 }}
              >
                <Typography variant="body1" color="textPrimary">
                  {filterRow.value.value.type}
                </Typography>
              </Box>
            )}
            <Box
              display={"flex"}
              justifyContent="flex-start"
              alignItems={"center"}
              style={{ flex: 1 }}
            >
              {printTagsFilterRow(filterRow)}
            </Box>
          </Box>
        );
      case AI_LANGUAGES:
        return (
          <Box
            width={"100%"}
            display="flex"
            justifyContent={"flex-start"}
            alignItems="center"
          >
            {filterRow.value.value.type && (
              <Box
                display={"flex"}
                justifyContent="flex-start"
                alignItems={"center"}
                style={{ flex: 1 }}
              >
                <Typography variant="body1" color="textPrimary">
                  {filterRow.value.value.type}
                </Typography>
              </Box>
            )}
            <Box
              display={"flex"}
              justifyContent="flex-start"
              alignItems={"center"}
              style={{ flex: 1 }}
            >
              <Typography
                variant="body1"
                color="textPrimary"
              >{`מינימום רמת ידע: ${
                LanguageLevels.find(
                  (sl) => sl.value === filterRow.value.value.value
                ).label
              }`}</Typography>
            </Box>
            <Box
              display={"flex"}
              justifyContent="flex-start"
              alignItems={"center"}
              style={{ flex: 1 }}
            >
              {printTagsFilterRow(filterRow)}
            </Box>
          </Box>
        );
      case AI_AVAILABILITY:
        return (
          <Box
            display={"flex"}
            justifyContent="flex-start"
            alignItems={"center"}
            style={{ flex: 1 }}
          >
            {printTagsFilterRow(filterRow)}
          </Box>
        );
      case AI_SALARY_EXPECTATIONS:
        return (
          <Box
            width={"100%"}
            display="flex"
            justifyContent={"flex-start"}
            alignItems="center"
          >
            <Box
              display={"flex"}
              justifyContent="flex-start"
              alignItems={"center"}
              style={{ flex: 1 }}
            >
              <Typography variant="body1" color="textPrimary">
                {`בין ${filterRow.value.value.value.salaryFrom} ל ${filterRow.value.value.value.salaryTo} ₪/חודש`}
                <br />
                <span style={{ fontSize: 14 }}>
                  מועמדים עם שכר שעתי יומרו לשכר חודשי לפי 182 שעות עבודה/חודש
                </span>
              </Typography>
            </Box>
            <Box
              display={"flex"}
              justifyContent="flex-start"
              alignItems={"center"}
              style={{ flex: 1 }}
            >
              {printTagsFilterRow(filterRow)}
            </Box>
          </Box>
        );
      case AI_LOCATION: {
        return (
          <Box
            display={"flex"}
            justifyContent="flex-start"
            alignItems={"center"}
            style={{ flex: 1 }}
          >
            {printTagsFilterRow(filterRow)}
          </Box>
        );
      }
      case "acceptedCities": {
        return (
          <Box
            width={"50%"}
            display="flex"
            justifyContent={"flex-start"}
            alignItems="center"
          >
            {filterRow.value.value.map((city, inx) => {
              return inx < filterRow.value.value.length - 1 ? (
                <Box
                  display={"flex"}
                  justifyContent="flex-start"
                  alignItems={"center"}
                  key={city}
                >
                  <Chip label={city} />
                  <Typography variant="body1"> או </Typography>
                </Box>
              ) : (
                <Chip label={city} key={city} />
              );
            })}
          </Box>
        );
      }
      default:
        break;
    }
  };
  return (
    filterRow.value.type !== "buildInTags" && (
      <Fragment>
        <Grid item xs={2} style={{ display: "flex", alignItems: "center" }}>
          <Typography variant="body1" color="textPrimary">
            {
              filterOptions.find((item) => item.name === filterRow.value.type)
                .label
            }
          </Typography>
        </Grid>
        <Grid
          item
          xs={withActions ? 8 : 10}
          className={classes.readRow}
          style={{ display: "flex", alignItems: "center" }}
        >
          <ErrorBoundary>{printRow()}</ErrorBoundary>
        </Grid>

        {withActions && (
          <Grid item xs={2}>
            <IconButton onClick={() => onRemove(filterRow.id)}>
              <CloseRounded style={{ color: "red" }} />
            </IconButton>
          </Grid>
        )}
      </Fragment>
    )
  );
};
