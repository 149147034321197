import React from "react";
import {
  Box,
  Divider,
  Avatar,
  Typography,
  TextField,
  FormControlLabel,
  Radio,
  Collapse,
  Grid,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import AddCancelButton from "../../../../components/AddCancelButtons/AddCancelButton";
import MailRoundedIcon from "@material-ui/icons/MailRounded";
import LastModifed from "../../../../components/LastModified/LastModified";
import CandidateRating from "../../../../components/CandidateRating/CandidateRating";
import SearchJob from "../../../../components/SearchJob/SearchJob";
import HighlightOffRoundedIcon from "@material-ui/icons/HighlightOffRounded";
import JobAcceptedQuestionierSummary from "../../../../components/JobAcceptedQuestionierSummary/JobAcceptedQuestionierSummary";
import CandidateInterview from "../../../../components/CandidateInterview/CandidateInterview";
import UnderJobContext from "../../../../context/underJobContext";
import DateFnsUtils from "@date-io/date-fns";
import { MenuMoreOptions } from "../../../../config/candidatesConfigData";
import green from "@material-ui/core/colors/green";
import { RawlIcon } from "../../../../Assets/CustomIcons/CustomIconsList";
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker,
} from "@material-ui/pickers";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "flex-start",
    flexFlow: "wrap",
    alignItems: "center",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    textOverflow: "ellipsis",
    "& .MuiAvatar-root": {
      marginRight: theme.spacing(2),
      marginLeft: theme.spacing(2),
    },
  },
  success: {
    color: green[500],
  },
  endIcon: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  buttons: {
    marginTop: theme.spacing(2),
  },
}));

const getIconForEventType = (eventId) => {
  const menuSelectedIndex = MenuMoreOptions.findIndex(
    (menuItem) => menuItem.id === eventId
  );
  if (menuSelectedIndex > -1) {
    return MenuMoreOptions[menuSelectedIndex].icon;
  }
};

const getViewBoxForEventType = (eventId) => {
  const menuSelectedIndex = MenuMoreOptions.findIndex(
    (menuItem) => menuItem.id === eventId
  );
  if (menuSelectedIndex > -1) {
    return MenuMoreOptions[menuSelectedIndex].viewBox;
  }
};

/*
Accepted Props:
eventTitle
eventType = list of eventTypes
authorImage
jobTitle - optional
drafMode - show add / cancel buttons
editable - show edit icon
disableLastModified
columnView - if true, row is displayed in column view
*/

const EventRow = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const [eventValue, setEventValue] = React.useState("");
  const [currenJob, setCurrentJob] = React.useState(true);
  const [interviewer, setInterviewer] = React.useState("");
  const [interviewDate, setInterviewDate] = React.useState(new Date());
  const [interviewAddress, setInterviewAddress] = React.useState("");
  const [isEditMode, setIsEditMode] = React.useState(false);
  const additionalInfo = React.useRef(null);

  const handleEditMode = (type) => {
    setIsEditMode(true);
    if (type === "sendToInterview") {
      setInterviewDate(props.interview.interview_date.toLocaleString());
      setInterviewer(props.interview.interviewer);
      setInterviewAddress(props.interview.interview_address);
    }
  };
  const handleCancelEditMode = () => {
    setIsEditMode(false);
  };
  const displayIconForType = (type) => {
    return (
      <RawlIcon
        color="primary"
        viewBox={getViewBoxForEventType(type)}
        size="small"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d={getIconForEventType(type)}
        />
      </RawlIcon>
    );
  };

  const onCommentChangeHandler = (event) => {
    setEventValue(event.target.value);
  };

  const onRatingChangeHandler = (value) => {
    setEventValue(value);
  };

  //also handle send invite to job
  const handleAddLinkToJob = (id) => {
    setEventValue(id);
  };
  // const handleUnSbReasonChange = (event) => {
  //   setUnsubscribeReason(event.target.value);
  // };

  // const handleOtherChanage = (event) => {
  //   setOther(event.target.value);
  // };

  const handleCancelItem = () => {
    props.cancelEvent();
  };
  const displayRowType = () => {
    if (props.draftMode || isEditMode) {
      switch (props.eventType) {
        case "addComment":
          return (
            <div
              style={{
                marginRight: theme.spacing(1),
                flexDirection: "column",
                //flex: 1,
                width: "100%",
                display: "flex",
                paddingLeft: theme.spacing(2),
                paddingRight: theme.spacing(2),
              }}
            >
              <TextField
                id="outlined-multiline-comments"
                label="הוסף הערה"
                multiline
                rows={3}
                variant="outlined"
                onChange={onCommentChangeHandler}
                value={eventValue}
                //style={{ flex: 1 }}
              />

              <Box
                mt={theme.spacing(1)}
                display="flex"
                alignItems="center"
                justifyContent={props.columnView ? "center" : "flex-start"}
              >
                <AddCancelButton
                  addCaption="הוסף"
                  cancelCaption="בטל"
                  addClick={() => props.onAddEvent("addComment", eventValue)}
                  cancelClick={handleCancelItem}
                />
              </Box>
            </div>
          );
        case "cvReferrals":
          return (
            <UnderJobContext.Consumer>
              {(context) => (
                <Box
                  display="flex"
                  flexDirection="column"
                  mr={props.columnView ? 0 : theme.spacing(1)}
                >
                  {context.job ? (
                    <React.Fragment>
                      <FormControlLabel
                        value="currentJob"
                        disabled={isEditMode}
                        checked={currenJob}
                        control={<Radio color="primary" />}
                        label="משרה זו"
                        onClick={(event) => setCurrentJob(true)}
                      />
                      <FormControlLabel
                        value="OtherJob"
                        checked={!currenJob}
                        disabled={isEditMode}
                        control={<Radio color="primary" />}
                        label="משרה אחרת"
                        onClick={(event) => setCurrentJob(false)}
                      />
                    </React.Fragment>
                  ) : null}

                  <Collapse in={context.job ? !currenJob : true}>
                    <SearchJob searchMode linkToJobId={handleAddLinkToJob} />
                  </Collapse>

                  <Box
                    display="flex"
                    justifyContent={props.columnView ? "center" : "flex-start"}
                    mt={theme.spacing(1)}
                  >
                    <AddCancelButton
                      addCaption={"אשר"}
                      cancelCaption="בטל"
                      addClick={() => {
                        setIsEditMode(false);
                        props.onAddEvent(
                          "cvReferrals",
                          context.job ? context.job.id : eventValue
                        );
                      }}
                      cancelClick={handleCancelItem}
                    />
                  </Box>
                </Box>
              )}
            </UnderJobContext.Consumer>
          );
        case "recruitmentSucceeded":
          return (
            <UnderJobContext.Consumer>
              {(context) => (
                <React.Fragment>
                  <Box
                    display="flex"
                    flexDirection="column"
                    style={{
                      width: "100%",
                      paddingRight: theme.spacing(2),
                      paddingLeft: theme.spacing(2),
                    }}
                  >
                    {context.job ? (
                      <React.Fragment>
                        <FormControlLabel
                          value="currentJob"
                          disabled={isEditMode}
                          checked={currenJob}
                          control={<Radio color="primary" />}
                          label="משרה זו"
                          onClick={(event) => setCurrentJob(true)}
                        />
                        <FormControlLabel
                          value="OtherJob"
                          checked={!currenJob}
                          disabled={isEditMode}
                          control={<Radio color="primary" />}
                          label="משרה אחרת"
                          onClick={(event) => setCurrentJob(false)}
                        />
                      </React.Fragment>
                    ) : (
                      <Typography variant="body1">
                        לאיזו משרה גויס המועמד?
                      </Typography>
                    )}
                    <Collapse in={context.job ? !currenJob : true}>
                      <SearchJob searchMode linkToJobId={handleAddLinkToJob} />
                    </Collapse>
                    <Collapse in={eventValue > 0}>
                      <Typography
                        variant="body1"
                        className={classes.success}
                        style={{ marginRight: theme.spacing(1) }}
                      >
                        מועמד גויס בהצלחה למשרה
                      </Typography>
                      <Box
                        display="flex"
                        flexDirection="column"
                        mr={props.columnView ? 0 : theme.spacing(1)}
                        mt={theme.spacing(2)}
                      >
                        <TextField
                          id="outlined-multiline-comments"
                          label="הוסף הערה"
                          multiline
                          rows={3}
                          variant="outlined"
                          inputRef={additionalInfo}
                          style={{ flex: 1 }}
                        />
                      </Box>
                    </Collapse>
                    <Box
                      display="flex"
                      justifyContent={
                        props.columnView ? "center" : "flex-start"
                      }
                      mt={theme.spacing(1)}
                    >
                      <AddCancelButton
                        addCaption="אשר גיוס"
                        cancelCaption="בטל"
                        addClick={() =>
                          props.onAddEvent(
                            "recruitmentSucceeded",
                            context.job && currenJob
                              ? context.job.id
                              : eventValue,
                            {
                              comments: additionalInfo
                                ? additionalInfo.current.value
                                : "",
                            }
                          )
                        }
                        cancelClick={handleCancelItem}
                      />
                    </Box>
                  </Box>
                </React.Fragment>
              )}
            </UnderJobContext.Consumer>
          );
        case "sendToInterview":
          return (
            <UnderJobContext.Consumer>
              {(context) => (
                <Box
                  display="flex"
                  flexDirection="column"
                  mr={props.columnView ? 0 : theme.spacing(1)}
                >
                  {context.job ? (
                    <React.Fragment>
                      <FormControlLabel
                        value="currentJob"
                        disabled={isEditMode}
                        checked={currenJob}
                        control={<Radio color="primary" />}
                        label="משרה זו"
                        onClick={(event) => setCurrentJob(true)}
                      />
                      <FormControlLabel
                        value="OtherJob"
                        checked={!currenJob}
                        disabled={isEditMode}
                        control={<Radio color="primary" />}
                        label="משרה אחרת"
                        onClick={(event) => setCurrentJob(false)}
                      />
                    </React.Fragment>
                  ) : null}

                  <Collapse in={context.job ? !currenJob : true}>
                    <SearchJob searchMode linkToJobId={handleAddLinkToJob} />
                  </Collapse>
                  <Grid
                    container
                    style={{ marginTop: theme.spacing(2) }}
                    justifyContent="center"
                    alignContent="center"
                  >
                    <Grid item xs={6}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDateTimePicker
                          margin="normal"
                          id="date-picker-dialog"
                          label="תאריך הראיון"
                          format="dd/MM/yyyy hh:mm"
                          minutesStep={5}
                          disablePast
                          value={interviewDate}
                          onChange={(date) =>
                            setInterviewDate(date.toLocaleString())
                          }
                          KeyboardButtonProps={{
                            "aria-label": "בחר תאריך",
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={6} style={{ paddingTop: theme.spacing(3) }}>
                      <TextField
                        id="interviewer"
                        label="שם המראיין"
                        value={interviewer}
                        fullWidth
                        onChange={(event) => setInterviewer(event.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: theme.spacing(1) }}>
                      <TextField
                        id="interviewAddress"
                        label="כתובת הראיון"
                        value={interviewAddress}
                        fullWidth
                        onChange={(event) =>
                          setInterviewAddress(event.target.value)
                        }
                      />
                    </Grid>
                  </Grid>

                  <Box
                    display="flex"
                    justifyContent={props.columnView ? "center" : "flex-start"}
                    mt={theme.spacing(1)}
                  >
                    <AddCancelButton
                      addCaption={isEditMode ? "עדכן" : "הוסף"}
                      cancelCaption="בטל"
                      addClick={() => {
                        setIsEditMode(false);
                        props.onAddEvent(
                          "sendToInterview",
                          context.job ? context.job.id : eventValue,
                          {
                            interviewDate: interviewDate.toLocaleString(),
                            interviewer,
                            interviewAddress,
                            eventId: isEditMode ? props.interview.id : -1,
                          },
                          isEditMode ? true : false
                        );
                      }}
                      cancelClick={
                        isEditMode ? handleCancelEditMode : handleCancelItem
                      }
                    />
                  </Box>
                </Box>
              )}
            </UnderJobContext.Consumer>
          );
        case "job_mail_sent":
        case "sendInvitation":
          return (
            <UnderJobContext.Consumer>
              {(context) => (
                <Box
                  display="flex"
                  flexDirection="column"
                  style={{
                    width: "100%",
                    paddingRight: theme.spacing(2),
                    paddingLeft: theme.spacing(2),
                  }}
                >
                  {context.job ? (
                    <React.Fragment>
                      <FormControlLabel
                        value="currentJob"
                        disabled={isEditMode}
                        checked={currenJob}
                        control={<Radio color="primary" />}
                        label="משרה זו"
                        onClick={(event) => setCurrentJob(true)}
                      />
                      <FormControlLabel
                        value="OtherJob"
                        checked={!currenJob}
                        disabled={isEditMode}
                        control={<Radio color="primary" />}
                        label="משרה אחרת"
                        onClick={(event) => setCurrentJob(false)}
                      />
                    </React.Fragment>
                  ) : (
                    <Typography variant="body1">
                      לאיזו משרה לשלוח זימון?
                    </Typography>
                  )}
                  <Collapse in={context.job ? !currenJob : true}>
                    <SearchJob searchMode linkToJobId={handleAddLinkToJob} />
                  </Collapse>
                  <Box
                    mt={theme.spacing(1)}
                    display="flex"
                    alignItems="center"
                    justifyContent={props.columnView ? "center" : "flex-start"}
                  >
                    <AddCancelButton
                      addCaption="שלח"
                      addIcon={<MailRoundedIcon />}
                      cancelCaption="בטל"
                      addClick={() =>
                        props.onAddEvent(
                          "sendInvitation",
                          context.job && currenJob ? context.job.id : eventValue
                        )
                      }
                      cancelClick={handleCancelItem}
                    />
                  </Box>
                </Box>
              )}
            </UnderJobContext.Consumer>
          );
        case "sendEmail":
          return (
            <div
              style={{
                marginRight: theme.spacing(1),
                flexDirection: "column",
                flex: 1,
                display: "flex",
              }}
            >
              <div style={{ marginTop: theme.spacing(1) }}>
                <AddCancelButton
                  addCaption="הוסף"
                  cancelCaption="בטל"
                  addClick={() => props.onAddEvent("addComment", eventValue)}
                  cancelClick={handleCancelItem}
                />
              </div>
            </div>
          );
        case "addRating":
          return (
            <Box
              display="flex"
              flexDirection="column"
              mr={props.columnView ? 0 : theme.spacing(1)}
            >
              <CandidateRating onRatingChange={onRatingChangeHandler} />

              <Box
                display="flex"
                justifyContent={props.columnView ? "center" : "flex-start"}
                mt={theme.spacing(1)}
              >
                <AddCancelButton
                  addCaption="הוסף"
                  cancelCaption="בטל"
                  addClick={() => props.onAddEvent("addRating", eventValue)}
                  cancelClick={handleCancelItem}
                />
              </Box>
            </Box>
          );
        case "unsubscribeRequest": {
          return (
            <Box
              display="flex"
              flexDirection="column"
              mr={props.columnView ? 0 : theme.spacing(1)}
              style={{
                width: "100%",
                paddingRight: theme.spacing(2),
                paddingLeft: theme.spacing(2),
              }}
            >
              <TextField
                id="outlined-multiline-comments"
                label="סיבת הוספה להסרה מרשימת דיוור:"
                multiline
                rows={3}
                variant="outlined"
                onChange={onCommentChangeHandler}
                value={eventValue}
                fullWidth
              />

              <Box
                display="flex"
                justifyContent={props.columnView ? "center" : "flex-start"}
                mt={theme.spacing(1)}
              >
                <AddCancelButton
                  addCaption="הוסף"
                  cancelCaption="בטל"
                  addClick={() =>
                    props.onAddEvent("unsubscribeRequest", eventValue)
                  }
                  cancelClick={handleCancelItem}
                />
              </Box>
            </Box>
          );
        }
        case "link_to_job": {
          //return create a search text to add job id or external job id with a button to view all jobs
          return (
            <div
              style={{
                marginRight: theme.spacing(1),
                flexDirection: "column",
                flex: 1,
                display: "flex",
              }}
            >
              <SearchJob searchMode linkToJobId={handleAddLinkToJob} />
              <Box
                mt={theme.spacing(1)}
                display="flex"
                alignItems="center"
                justifyContent={props.columnView ? "center" : "flex-start"}
              >
                <AddCancelButton
                  addCaption="הוסף"
                  cancelCaption="בטל"
                  addClick={() => props.onAddEvent("link_to_job", eventValue)}
                  cancelClick={handleCancelItem}
                />
              </Box>
            </div>
          );
        }
        case "removeFromJob": {
          return (
            <UnderJobContext.Consumer>
              {(context) => (
                <div
                  style={{
                    marginRight: theme.spacing(1),
                    flexDirection: "column",
                    flex: 1,
                    display: "flex",
                  }}
                >
                  {context.job ? (
                    <React.Fragment>
                      <FormControlLabel
                        value="currentJob"
                        checked={currenJob}
                        control={<Radio color="primary" />}
                        label="משרה זו"
                        onClick={(event) => setCurrentJob(true)}
                      />
                      <FormControlLabel
                        value="OtherJob"
                        checked={!currenJob}
                        control={<Radio color="primary" />}
                        label="משרה אחרת"
                        onClick={(event) => setCurrentJob(false)}
                      />
                    </React.Fragment>
                  ) : null}
                  <Collapse in={context.job ? !currenJob : true}>
                    <SearchJob searchMode linkToJobId={handleAddLinkToJob} />
                  </Collapse>
                  <Box
                    mt={theme.spacing(1)}
                    display="flex"
                    alignItems="center"
                    justifyContent={props.columnView ? "center" : "flex-start"}
                  >
                    <AddCancelButton
                      addCaption="הסר שיוך למשרה"
                      addIcon={<HighlightOffRoundedIcon />}
                      cancelCaption="בטל"
                      addClick={() =>
                        props.onAddEvent(
                          "removeFromJob",
                          currenJob ? context.job.id : eventValue
                        )
                      }
                      cancelClick={handleCancelItem}
                    />
                  </Box>
                </div>
              )}
            </UnderJobContext.Consumer>
          );
        }
        // case "addReminder":
        //   return <ScheduleRoundedIcon style={{ color: grey[500] }} />;
        case "addPhoneInterview":
          return (
            <UnderJobContext.Consumer>
              {(context) => (
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  mr={props.columnView ? 0 : theme.spacing(1)}
                >
                  {context.job ? (
                    <React.Fragment>
                      <FormControlLabel
                        value="currentJob"
                        disabled={isEditMode}
                        checked={currenJob}
                        control={<Radio color="primary" />}
                        label="משרה זו"
                        onClick={(event) => setCurrentJob(true)}
                      />
                      <FormControlLabel
                        value="OtherJob"
                        checked={!currenJob}
                        disabled={isEditMode}
                        control={<Radio color="primary" />}
                        label="משרה אחרת"
                        onClick={(event) => setCurrentJob(false)}
                      />
                    </React.Fragment>
                  ) : null}

                  <Collapse in={context.job ? !currenJob : true}>
                    <SearchJob searchMode linkToJobId={handleAddLinkToJob} />
                  </Collapse>
                  <TextField
                    id="outlined-multiline-comments"
                    label="סיכום ריאיון טלפוני:"
                    multiline
                    rows={3}
                    variant="outlined"
                    onChange={onCommentChangeHandler}
                    value={eventValue}
                    style={{ marginTop: theme.spacing(1) }}
                  />
                  <Box
                    mt={theme.spacing(1)}
                    display="flex"
                    alignItems="center"
                    justifyContent={props.columnView ? "center" : "flex-start"}
                  >
                    <AddCancelButton
                      addCaption={isEditMode ? "עדכן" : "הוסף"}
                      cancelCaption="בטל"
                      addClick={() => {
                        setIsEditMode(false);
                        props.onAddEvent(
                          "addPhoneInterview",
                          context.job.id,
                          eventValue
                        );
                      }}
                      cancelClick={
                        isEditMode ? handleCancelEditMode : handleCancelItem
                      }
                    />
                  </Box>
                </Box>
              )}
            </UnderJobContext.Consumer>
          );
        // case "blockCandidate":
        //   return <BlockRoundedIcon style={{ color: grey[500] }} />;
        case "advancedRecruitmentProcedures":
          return (
            <UnderJobContext.Consumer>
              {(context) => (
                <Box
                  display="flex"
                  flexDirection="column"
                  mr={props.columnView ? 0 : theme.spacing(1)}
                  style={{
                    width: "100%",
                    paddingRight: theme.spacing(2),
                    paddingLeft: theme.spacing(2),
                  }}
                >
                  <TextField
                    id="outlined-multiline-comments"
                    label="הערה לתהליכי גיוס מתקדמים"
                    multiline
                    rows={3}
                    variant="outlined"
                    onChange={onCommentChangeHandler}
                    value={eventValue}
                    style={{ flex: 1 }}
                  />

                  <Box
                    display="flex"
                    justifyContent={props.columnView ? "center" : "flex-start"}
                    mt={theme.spacing(1)}
                  >
                    <AddCancelButton
                      addCaption="הוסף"
                      cancelCaption="בטל"
                      addClick={() =>
                        props.onAddEvent(
                          "advancedRecruitmentProcedures",
                          context.job.id,
                          eventValue
                        )
                      }
                      cancelClick={handleCancelItem}
                    />
                  </Box>
                </Box>
              )}
            </UnderJobContext.Consumer>
          );
        default:
          //default: link to job
          return (
            <div style={{ display: "flex", justifyContent: "flex-start" }}>
              <Typography variant="body1">{props.eventTitle}</Typography>
              <Typography
                variant="body1"
                style={{ fontWeight: 600, marginRight: theme.spacing(1) }}
              >
                {props.jobTitle ? props.jobTitle : ""}
              </Typography>
              {props.disableLastModified ? null : (
                <LastModifed toDate={props.event.event_date} />
              )}
            </div>
          );
      }
    } else {
      return (
        <React.Fragment>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            <Typography
              variant="body1"
              className={
                props.event.event_key === "recruitmentSucceeded"
                  ? classes.success
                  : ""
              }
            >
              {props.eventTitle ? props.eventTitle : props.event.event_value}
            </Typography>
            <div
              className={`${classes.endIcon} ${
                props.event.event_key === "recruitmentSucceeded"
                  ? classes.success
                  : ""
              }`}
            >
              {props.endIcon ? props.endIcon : null}
            </div>

            <Typography
              variant="body1"
              style={{ fontWeight: 600, marginRight: theme.spacing(1) }}
            >
              {props.eventTitle ? props.jobTitle : ""}
            </Typography>
            {props.eventType === "addRating" ? (
              <CandidateRating ratingValue={props.event.event_value} />
            ) : null}
            {props.disableLastModified ? null : (
              <LastModifed toDate={props.event.event_date} />
            )}
          </div>

          {props.eventType === "candidate_accepted_job" ? (
            <JobAcceptedQuestionierSummary
              candidateId={props.event.event_candidate_id}
              jobId={props.event.job_id}
            />
          ) : null}

          {props.eventType === "sendToInterview" ? (
            <CandidateInterview
              interview={props.interview}
              editEvent={() => handleEditMode("sendToInterview")}
            />
          ) : null}
          {props.eventType === "advancedRecruitmentProcedures" ||
          props.eventType === "addPhoneInterview" ? (
            <div>
              <Typography variant="body1">
                <strong>מידע נוסף:</strong> {props.event.event_value}
              </Typography>
            </div>
          ) : null}
          {props.eventType === "recruitmentSucceeded" ? (
            <div>
              <Typography variant="body1">
                <strong>מידע נוסף:</strong> {props.event.event_value}
              </Typography>
            </div>
          ) : null}
        </React.Fragment>
      );
    }
  };

  return (
    <React.Fragment>
      <Box
        display="flex"
        justifyContent="flex-start"
        alignItems="center"
        flexDirection={props.columnView ? "column" : "row"}
      >
        <div className={classes.root}>
          {displayIconForType(
            props.eventType ? props.eventType : props.event.event_key
          )}
          <Avatar
            alt=""
            src={props.authorImage ? props.authorImage : undefined}
            className={classes.large}
          />
        </div>
        {displayRowType()}
      </Box>
      {!props.draftMode && !props.isEditMode && (
        <Box my={theme.spacing(2)}>
          <Divider />
        </Box>
      )}
    </React.Fragment>
  );
};
export default EventRow;
